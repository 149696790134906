import styled from "styled-components";
import React, { ReactElement } from "react";
import { Context } from "../../State/store";
import { Image } from "antd";
import SideNavFilters from "./SideNavFilters";
import salesLineLogo from "salesline-logo.svg";
import { animated, config, useSpring } from "react-spring";
import { RoutePaths } from "../Router/Routes";
import { Link } from "react-router-dom";

const StyledSider = styled.div<{ width: number }>`
    background: white;
    border-right: 1px solid #d9d9d9;
    width: ${({ width }) => `${width}px;`};
`;

const AnimatedDiv = animated.div;

const PaddedFixedDiv = styled(AnimatedDiv)`
    padding: 20px;
    position: fixed;
    width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
`;

const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 4px 0px 16px 0px;
    flex-direction: row;
`;

export default function SideNavigation(): ReactElement {
    const [{ groupData }] = React.useContext(Context);

    const styles = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 100,
        config: config.stiff,
    });

    return (
        <>
            {groupData ? (
                <StyledSider width={300}>
                    <PaddedFixedDiv style={styles}>
                        <LogoContainer>
                            <Link to={RoutePaths.HOME}>
                                <img src={salesLineLogo} width={"100%"} />
                            </Link>
                        </LogoContainer>
                        <SideNavFilters />
                    </PaddedFixedDiv>
                </StyledSider>
            ) : (
                <StyledSider width={300} />
            )}
        </>
    );
}
