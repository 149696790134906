import { MappingSegment, Segment } from "Api/mappingConfigurator";
import { useGetMappingRuleById } from "Hooks/mappingConfigurator";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    JsonGroup,
    Utils as QbUtils,
    WidgetProps,
} from "react-awesome-query-builder";
import {
    getFieldSettings,
    parseTreeLogic,
    validateMappingObject,
} from "Utils/mapping-configurator-utils";
import { MappedSegmentTypes } from "./MappingConfiguratorEditorPage";
import { useQuery } from "react-query";
import {
    MappingRuleV2,
    UnmappedSegment,
    createMappingRule,
    fetchAllSegments,
    fetchUnmappedSegments,
    updateMappingRuleById,
} from "Api/mappingConfiguratorV2";
import { openNotificationWithIcon } from "Components/Notification";
import { message } from "antd";
import {
    useConfig,
    useMappingRule,
    useRuleBuilder,
    useVenueNameById,
} from "./MappingConfiguratorEditorPage.logic.hooks";

export type Result = WidgetProps & {
    listValues: any;
    allowCustomValues: boolean;
    setValue?: (value: any) => void;
};

export type IntegrationItem = {
    [key: string]: any;
    integrationSource: string;
};

function extractSegmentIds(data: IntegrationItem[]): number[] {
    const allIds: number[] = [];

    data.forEach((item) => {
        Object.keys(item).forEach((key) => {
            if (key.endsWith("Ids")) {
                const ids = item[key] as number[];
                allIds.push(...ids);
            }
        });
    });

    return allIds;
}

type InputObject = {
    id: string;
    type: string;
    children1?: Record<string, any>;
    properties?: Record<string, any>;
};

type OutputObject = {
    id: string;
    type: string;
    children1?: Record<string, any>;
    path?: string[];
} & Record<string, any>;

// export function convertRules(input: InputObject): OutputObject {
//     const output: OutputObject = {
//         id: input.id,
//         type: input.type,
//     };
//
//     if (input.properties) {
//         for (const key in input.properties) {
//             if (
//                 key === "value" &&
//                 Array.isArray(input.properties[key]) &&
//                 !Array.isArray(input.properties[key][0])
//             ) {
//                 output[key] = [input.properties[key]];
//             } else {
//                 output[key] = input.properties[key];
//             }
//         }
//     }
//
//     if (input.children1) {
//         output.children1 = {};
//         for (const key in input.children1) {
//             output.children1[key] = convertRules(input.children1[key]);
//         }
//     }
//
//     return output;
// }

function convertUnmappedSegmentToSegment(unmappedSegment: UnmappedSegment): Segment {
    return {
        segmentId: unmappedSegment.segment_id,
        segmentType: unmappedSegment.segment_type as MappingSegment,
        segmentName: unmappedSegment.segment_value,
        integrationName: unmappedSegment.integration_name,
        parentSegmentId: unmappedSegment.parent_segment_id
            ? parseInt(unmappedSegment.parent_segment_id)
            : undefined,
        id: unmappedSegment.id,
        serviceId: unmappedSegment.service_id,
    };
}

export function convertUnmappedSegmentResponseToSegments(
    response: UnmappedSegment[]
): Segment[] {
    return response.map(convertUnmappedSegmentToSegment);
}

export const useMappingConfiguratorEditorPage = (id: string) => {
    const hasMounted = useRef(false);

    const { data: existingMappingRule, isFetching: fetchingExistingMappingRule } =
        useGetMappingRuleById(id);

    const { mappingRule, setMappingRule } = useMappingRule(id, existingMappingRule);

    const { data: segments, isLoading: v2SegmentsLoading } = useQuery(
        "V2_ALL_SEGMENTS",
        fetchAllSegments,
        {
            staleTime: Infinity,
            select: (result) => {
                if (!result) return [];

                return convertUnmappedSegmentResponseToSegments(result);
            },
        }
    );

    const [parsedMappingRules, setParsedMappingRules] =
        useState<MappedSegmentTypes>();

    const venueNameById = useVenueNameById(segments);

    const fieldSettings = useMemo(() => {
        if (!parsedMappingRules) return undefined;
        return segments && venueNameById
            ? getFieldSettings({
                  segments,
                  knownMappedRules: parsedMappingRules,
                  selectedSegment:
                      (mappingRule.segment_type as "class" | "area" | "venue") ??
                      undefined,
              })
            : undefined;
    }, [venueNameById, segments, parsedMappingRules, mappingRule]);

    const config = useConfig(fieldSettings);

    //  if (hasDuplicateValues(fieldSettings ?? {})) {
    //      openNotificationWithIcon({
    //          title: "Duplicate values found in field settings",
    //          type: "error",
    //      });
    //  }

    const { ruleBuilder, setRuleBuilder } = useRuleBuilder(
        id,
        config,
        existingMappingRule?.rules
    );

    const mappingRuleDirty = useMemo(
        () =>
            ruleBuilder
                ? !isEqual(existingMappingRule, mappingRule) ||
                  !isEqual(QbUtils.getTree(ruleBuilder), existingMappingRule?.rules)
                : false,
        [existingMappingRule, mappingRule, ruleBuilder]
    );

    const mappingRuleValid =
        ruleBuilder && config
            ? Boolean(mappingRule.segment_name) &&
              Boolean(mappingRule.segment_type) &&
              validateMappingObject({ tree: ruleBuilder, config })
            : false;

    const mappingRuleParsingResult = useMemo(() => {
        if (!mappingRuleValid || !ruleBuilder) {
            return;
        }

        return parseTreeLogic(ruleBuilder, config);
    }, [mappingRuleValid, ruleBuilder]);

    const saveMappingRule = async () => {
        if (!mappingRuleValid || !ruleBuilder) {
            return;
        }

        if (!mappingRuleParsingResult) {
            return;
        }
        const id = mappingRule.id ?? mappingRule.uuid;

        if (existingMappingRule?.rules && !id) {
            openNotificationWithIcon({
                type: "error",
                title: "Error",
                description: "Mapping Rule ID is missing",
            });

            return;
        }
        const completedMappingRule: MappingRuleV2 = {
            //@ts-ignore
            id,
            //@ts-ignore
            uuid: id,
            segment_type: mappingRule.segment_type!,
            segment_name: mappingRule.segment_name!,
            // @ts-ignore TODO
            rules: QbUtils.getTree(ruleBuilder),
            // @ts-ignore TODO
            result: mappingRuleParsingResult,
            exclude: mappingRule.exclude,
            // TODO THIS
            unmapped_segments: extractSegmentIds(mappingRuleParsingResult),
        };
        // TODO I need to provide a list of all the segments that are mapped to this integration

        if (existingMappingRule?.rules) {
            message.info("Updating mapping rule");
            await updateMappingRuleById(
                completedMappingRule.id,
                completedMappingRule
            );
        } else {
            message.info("Creating new mapping rule");
            await createMappingRule(completedMappingRule);
        }
        openNotificationWithIcon({
            type: "success",
            title: "Mapping rule saved",
        });
    };

    useEffect(() => {
        if (existingMappingRule?.rules) {
            setMappingRule(existingMappingRule);
            hasMounted.current = true;
        }
    }, [existingMappingRule, setMappingRule]);

    useEffect(() => {
        if (existingMappingRule?.rules && config) {
            setRuleBuilder(
                QbUtils.loadTree(existingMappingRule.rules as any as JsonGroup)
            );
        }
    }, [config, existingMappingRule?.rules, setRuleBuilder]);

    return {
        fetchingExistingMappingRule,
        config,
        mappingRule,
        setMappingRule,
        ruleBuilder,
        setRuleBuilder,
        mappingRuleDirty,
        saveMappingRule,
        mappingRuleValid,
        segments,
        venueNameById,
        mappingRuleParsingResult,
        parsedMappingRules,
        setParsedMappingRules,
        isLoading: v2SegmentsLoading,
    };
};
