import { useState, useEffect } from "react";
import {
    FieldSettings,
    JsonGroup,
    Utils as QbUtils,
} from "react-awesome-query-builder";
import { useMemo } from "react";
import { chain, cloneDeep } from "lodash";
import { MappingRuleV2 } from "Api/mappingConfiguratorV2";
import AntdConfig from "react-awesome-query-builder/lib/config/antd";
import React from "react";
import { MultiSelectWidget } from "./AntDesignComponents/MultiSelectWidget";
import { Segment } from "Api/mappingConfigurator";

const defaultConfig = cloneDeep(AntdConfig);

defaultConfig.widgets.multiselect.factory = (props: any) => {
    return React.cloneElement(<MultiSelectWidget {...props} />, {});
};

const {
    is_null,
    is_not_null,
    not_equal,
    select_not_equals,
    multiselect_not_equals,
    ...defaultOperators
} = AntdConfig.operators;

export const useMappingRule = (
    id: string,
    existingMappingRule: MappingRuleV2 | undefined
) => {
    const [mappingRule, setMappingRule] = useState<Partial<MappingRuleV2>>({
        id,
    });

    useEffect(() => {
        if (existingMappingRule) {
            setMappingRule(existingMappingRule);
        }
    }, [existingMappingRule, setMappingRule]);

    return { mappingRule, setMappingRule };
};

export const useVenueNameById = (segments: Segment[] | undefined) => {
    return useMemo(
        () =>
            segments
                ? chain(segments)
                      .filter(({ segmentType }) => segmentType === "venue")
                      .keyBy(
                          ({ integrationName, segmentId }) =>
                              `${integrationName}_${segmentId}`
                      )
                      .mapValues("id")
                      .value()
                : undefined,
        [segments]
    );
};

export const useConfig = (fieldSettings: FieldSettings | undefined): any => {
    return useMemo(
        () =>
            fieldSettings
                ? {
                      ...defaultConfig,
                      operators: defaultOperators,
                      settings: { ...defaultConfig.settings, showNot: false },
                      fields: { ...fieldSettings },
                  }
                : undefined,
        [fieldSettings, defaultConfig]
    );
};

export const useRuleBuilder = (
    id: string,
    config: any,
    existingMappingRules: { [key: string]: string | number } | undefined
) => {
    const [ruleBuilder, setRuleBuilder] = useState(
        config
            ? QbUtils.checkTree(QbUtils.loadTree({ id, type: "group" }), config)
            : QbUtils.loadTree({ id, type: "group" })
    );

    useEffect(() => {
        if (existingMappingRules && config) {
            setRuleBuilder(
                QbUtils.loadTree(existingMappingRules as any as JsonGroup)
            );
        }
    }, [config, existingMappingRules, setRuleBuilder]);

    return { ruleBuilder, setRuleBuilder };
};
