import { Typography } from "antd";
import Table from "antd/lib/table";
import React, { FC } from "react";
import styled from "styled-components";
import { WRANGLR_DARK_BLUE } from "Utils/constants";

import {
    SegmentTableProps,
    SegmentTableRowRecord,
    useSegmentTable,
} from "./SegmentTable.logic";

const PaddedTableTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
    padding-left: 5px;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        font-size: 14px;
        opacity: 0.5;
    }
`;

export const SegmentTable: FC<SegmentTableProps> = (props) => {
    const { onRowClick, getRowClassName, isLoading, columns, dataSource } =
        useSegmentTable(props);

    const { segment, omitTitle } = props;

    return (
        <>
            {!omitTitle ? (
                <PaddedTableTitle level={4}>{segment}</PaddedTableTitle>
            ) : null}
            <Table
                className={"segment-table"}
                style={{ borderRadius: "15px" }}
                onRow={(record: SegmentTableRowRecord) => {
                    return {
                        onClick: onRowClick(record),
                    };
                }}
                rowClassName={getRowClassName}
                loading={isLoading}
                columns={columns}
                dataSource={dataSource}
                pagination={{ pageSize: 5, size: "small" }}
                scroll={{ x: "max-content" }}
                tableLayout={"auto"}
                showSorterTooltip={false}
                size={"small"}
            />
        </>
    );
};
