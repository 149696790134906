import { Segment } from "Api/mappingConfigurator";
import { Dictionary } from "lodash";
import React, { useEffect, useMemo } from "react";
import {
    BasicConfig,
    Builder,
    BuilderProps,
    Config,
    ImmutableTree,
    Query,
} from "react-awesome-query-builder";
import { getFieldSettings, parseTreeLogic } from "Utils/mapping-configurator-utils";
import { MappedSegmentTypes } from "./MappingConfiguratorEditorPage";

interface Props {
    rules: ImmutableTree;
    config: BasicConfig;
    onRulesChanged: (immutableTree: ImmutableTree, config: Config) => void;
    segments?: Segment[];
    venueNameById: Dictionary<number>;
    knownMappedRules: MappedSegmentTypes | undefined;
    currentSelectedSegmentType?: string;
}

export const RuleBuilder: React.FC<Props> = ({
    rules,
    onRulesChanged,
    config,
    segments,
    venueNameById,
    knownMappedRules,
    currentSelectedSegmentType,
}) => {
    const getFilteredConfig = useMemo(() => {
        const mappingRuleParsingResult = parseTreeLogic(rules, config);

        if (!mappingRuleParsingResult || !segments || !knownMappedRules) {
            return config;
        }

        return {
            ...config,
            // fields: {
            //     ...getFieldSettings({
            //         segments,
            //         knownMappedRules,
            //         selectedSegment: currentSelectedSegmentType as
            //             | "area"
            //             | "class"
            //             | "venue",
            //     }),
            // },
        };
    }, [
        knownMappedRules,
        config,
        currentSelectedSegmentType,
        rules,
        segments,
        venueNameById,
    ]);

    // useEffect(() => {
    //     console.log({ knownMappedRules });
    //     console.log({ getFilteredConfig });
    // }, [knownMappedRules, getFilteredConfig]);

    return (
        <div style={{ marginBottom: 20 }}>
            <Query
                {...getFilteredConfig}
                value={rules}
                onChange={onRulesChanged}
                renderBuilder={(props: BuilderProps) => {
                    return (
                        <div className="query-builder-container">
                            <div className="query-builder qb-lite">
                                <Builder {...props} />
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );
};
