import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
} from "react";
import CheckboxWithDrawers from "./index";
import { drawerType } from "../SideNavigation/CheckboxManager";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { useClassesFilter } from "Store/filterStore";
import { chain } from "lodash";

interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}

export default function ClassesCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const { data: mappingRule } = useListMappingRules();
    const { selectedClasses: selectedClassIds, updateClassesFilter } =
        useClassesFilter();

    const classNameById = chain(mappingRule)
        .keyBy("uuid")
        .mapValues("segment_name")
        .value();

    const classIdByName = chain(mappingRule)
        .keyBy("segment_name")
        .mapValues("uuid")
        .value();

    const classes =
        mappingRule
            ?.filter(
                ({ segment_type, exclude }) =>
                    segment_type === "class" && exclude !== true
            )
            .map(({ segment_name }) => segment_name) ?? [];

    const selectedClasses =
        selectedClassIds?.map((classId) => classNameById[classId] ?? classId) ?? [];

    const dispatchAction = useCallback(
        (selectedClasses: string[]) => {
            if (mappingRule) {
                updateClassesFilter(
                    selectedClasses.map((className) => classIdByName[className])
                );
            }
        },
        [mappingRule, updateClassesFilter]
    );

    useEffect(() => {
        dispatchAction([]);
    }, []);

    return (
        <>
            <CheckboxWithDrawers
                options={classes.sort()}
                selectedOptions={selectedClasses}
                dispatchAction={dispatchAction}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Classes"}
                title={"Classes"}
            />
        </>
    );
}
