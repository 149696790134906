import { BACKEND_API_URL } from "Utils/constants";
import { getAuthTokens } from "./auth0";

// Request body type for POST and PUT requests to /api/v2/mapping-rules
export interface MappingRuleV2 {
    id: string;
    uuid: string;
    client_group: number;
    created_date: string;
    last_modified_date: string;
    rules: {
        [key: string]: number | string;
    };
    result: any[];
    segment_type: string;
    segment_name: string;
    // TODO Adjust this type
    unmapped_segments: any[]; // number[];
    exclude?: boolean;
}

// Response body type for GET and POST requests to /api/v2/mapping-rules and PUT requests to /api/v2/mapping-rules/{id}
type MappingRuleV2Response = MappingRuleV2[];

// Response body type for GET requests to /api/v2/mapping-rules/{id}
type MappingRuleV2ByIdResponse = MappingRuleV2;

// Response body type for GET requests to /api/v2/mapping-rules/unmapped-segments
export interface UnmappedSegment {
    id: number; // This is the source of truth
    segment_name: string;
    group: string;
    segment_type: string;
    segment_value: string;
    segment_id: number;
    integration_name: string;
    parent_segment_id: string | null;
    service_id: number;
}

// Response body type for GET requests to /api/v2/mapping-rules/unmapped-segments
type UnmappedSegmentResponse = UnmappedSegment[];

async function getAuthHeaders(): Promise<Record<string, string>> {
    const data = await getAuthTokens();

    if (!data) {
        throw new Error("Failed to obtain authentication tokens");
    }

    const accessToken = data.access_token;

    const headers = {
        Authorization: `bearer ${accessToken}`,
        "Content-Type": "application/json",
    };

    return headers;
}

async function fetchWithAuth(url: string, options: RequestInit): Promise<Response> {
    const headers = await getAuthHeaders();
    return fetch(`${BACKEND_API_URL}${url}`, {
        ...options,
        headers: { ...headers, ...(options.headers || {}) },
        credentials: "include",
        redirect: "follow",
    });
}

// Fetch all mapping rules
async function fetchMappingRules(): Promise<MappingRuleV2Response> {
    const response = await fetchWithAuth("/api/v2/mapping-rules", {
        method: "GET",
        credentials: "include",
    });
    return response.json();
}

// Create a new mapping rule
async function createMappingRule(data: MappingRuleV2): Promise<MappingRuleV2> {
    const response = await fetchWithAuth("/api/v2/mapping-rules", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(data),
    });
    return response.json();
}

// Fetch a specific mapping rule by ID
async function fetchMappingRuleById(id: string): Promise<MappingRuleV2ByIdResponse> {
    const response = await fetchWithAuth(`/api/v2/mapping-rules/${id}`, {
        method: "GET",
        credentials: "include",
    });
    return response.json();
}
async function deleteMappingRuleById(id: string): Promise<void> {
    const response = await fetchWithAuth(`/api/v2/mapping-rules/${id}`, {
        method: "DELETE",
        credentials: "include",
    });

    if (!response.ok) {
        throw new Error(`Failed to delete mapping rule with ID: ${id}`);
    }
}

// Update an existing mapping rule by ID
async function updateMappingRuleById(
    id: string,
    data: MappingRuleV2
): Promise<MappingRuleV2> {
    const response = await fetchWithAuth(`/api/v2/mapping-rules/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify(data),
    });
    return response.json();
}

// Fetch all unmapped segments
async function fetchUnmappedSegments(): Promise<UnmappedSegmentResponse> {
    const response = await fetchWithAuth("/api/v2/mapping-rules/unmapped-segments", {
        method: "GET",
        credentials: "include",
    });

    return response.json();
}

async function fetchAllSegments(): Promise<UnmappedSegmentResponse> {
    const response = await fetchWithAuth("/api/v1/unmapped-segments", {
        method: "GET",
        credentials: "include",
    });

    return response.json();
}

export {
    fetchMappingRules,
    createMappingRule,
    fetchMappingRuleById,
    updateMappingRuleById,
    fetchUnmappedSegments,
    deleteMappingRuleById,
    fetchAllSegments,
};
