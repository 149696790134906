import React, {
    CSSProperties,
    ReactElement,
    useCallback,
    useEffect,
    useState,
} from "react";
import { BaselineMappedRow } from "./BaselineChart";
import { ColumnType } from "antd/es/table";
import { Skeleton, Table, Typography } from "antd";
import "../HomeDashboardDesktop/segmentTable.scss";
import { currencyFormatter, stringSort } from "../../Utils/utils";
import { FilteredSegments } from "./index";
import { useDebounceEffect } from "ahooks";
import { WRANGLR_DARK_BLUE } from "Utils/constants";

const onHeaderCell = (): { style: CSSProperties } => ({
    style: {
        color: WRANGLR_DARK_BLUE,
        fontWeight: "bold" as const,
    },
});

interface ForecastingSegmentTableRecord {
    segment: string;
    base: number;
    forecast?: number;
}

const getColumns = (
    mode: "baseline" | "drivers"
): ColumnType<ForecastingSegmentTableRecord>[] => {
    const columns: ColumnType<ForecastingSegmentTableRecord>[] = [];
    columns.push({
        key: "segment",
        title: `Name`,
        dataIndex: "segment",
        className: "segment-table-column",
        sortDirections: ["descend", "ascend"],
        onHeaderCell,
        sorter: (
            a: ForecastingSegmentTableRecord,
            b: ForecastingSegmentTableRecord
        ) => stringSort(a.segment, b.segment),
    });
    columns.push({
        key: "base",
        title: `Base`,
        dataIndex: "base",
        className: "segment-table-column",
        render: (val) => currencyFormatter(val, 1),
        sortDirections: ["descend", "ascend"],
        sorter: (
            a: ForecastingSegmentTableRecord,
            b: ForecastingSegmentTableRecord
        ) => a.base - b.base,
        onHeaderCell,
    });
    if (mode === "drivers") {
        columns.push({
            key: "forecast",
            title: `Forecast`,
            dataIndex: "forecast",
            className: "segment-table-column",
            render: (val) => currencyFormatter(val, 1),
            sortDirections: ["descend", "ascend"],
            sorter: (
                a: ForecastingSegmentTableRecord,
                b: ForecastingSegmentTableRecord
            ) => a.forecast! - b.forecast!,
            onHeaderCell,
        });
    }
    return columns;
};

interface Props {
    mappedData: BaselineMappedRow[];
    loading: boolean;
    mode: "baseline" | "drivers";
    setFilteredSegments: React.Dispatch<React.SetStateAction<FilteredSegments>>;
    segmentType: "days" | "areas" | "classes";
}

const SegmentTable = ({
    mappedData,
    loading,
    mode,
    setFilteredSegments,
    segmentType,
}: Props): ReactElement => {
    const [columns, setColumns] = useState<
        ColumnType<ForecastingSegmentTableRecord>[]
    >([]);
    const [dataSource, setDataSource] = useState<ForecastingSegmentTableRecord[]>(
        []
    );
    const [inclusionList, setInclusionList] = useState<string[]>([]);

    useEffect(() => {
        setColumns(getColumns(mode));
    }, [mode]);

    useEffect(() => {
        setDataSource(
            mappedData.map(({ x, base, forecast }: BaselineMappedRow) => ({
                segment: x,
                key: x,
                base,
                forecast,
            }))
        );
    }, [mappedData]);

    useDebounceEffect(
        () => {
            setFilteredSegments((excludedSegments: FilteredSegments) => {
                const newExcludedSegments = { ...excludedSegments };
                newExcludedSegments[segmentType] = inclusionList;
                return newExcludedSegments;
            });
        },
        [inclusionList],
        {
            wait: 500,
        }
    );

    const onRowClick = (record: ForecastingSegmentTableRecord) => () => {
        //Remove segment from exclusion list if it exists, otherwise, add it in
        if (inclusionList.includes(record.segment)) {
            setInclusionList((excludedList: string[]) =>
                excludedList.filter((item: string) => item !== record.segment)
            );
        } else {
            setInclusionList((excludedList: string[]) =>
                excludedList.concat(record.segment)
            );
        }
    };

    const setRowClassName = (record: ForecastingSegmentTableRecord) => {
        if (inclusionList.find((item) => item === record.segment) !== undefined) {
            return "selected-row";
        }
        return "";
    };

    const getSummary = useCallback(
        (data: any) => {
            let totalBase = 0;
            let totalForecast = 0;

            data.forEach(({ base, forecast }) => {
                totalBase += base;
                totalForecast += forecast!;
            });

            return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                            <Typography.Text strong>Totals</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                            <Typography.Text strong>
                                {currencyFormatter(totalBase)}
                            </Typography.Text>
                        </Table.Summary.Cell>
                        {mode === "drivers" && (
                            <Table.Summary.Cell index={2}>
                                <Typography.Text strong>
                                    {currencyFormatter(totalForecast)}
                                </Typography.Text>
                            </Table.Summary.Cell>
                        )}
                    </Table.Summary.Row>
                </>
            );
        },
        [mode]
    );

    if (loading) {
        return <Skeleton active paragraph={{ rows: 6 }} />;
    }

    return (
        <Table
            columns={columns}
            className={"segment-table"}
            dataSource={dataSource}
            pagination={{ pageSize: 7, size: "small", hideOnSinglePage: true }}
            tableLayout={"auto"}
            showSorterTooltip={false}
            size={"small"}
            onRow={(record: ForecastingSegmentTableRecord) => ({
                onClick: onRowClick(record),
            })}
            rowClassName={setRowClassName}
            summary={getSummary}
        />
    );
};

export default SegmentTable;
