import React from "react";
import { Button, List, Popover, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { MomentFormat } from "Utils/date-utils";
import { prependDollarSymbol } from "Utils/number-utils";
import { SalesAffectedEvent } from "../ForecastModel";
import { EditOutlined } from "@ant-design/icons";

interface Props {
    events: SalesAffectedEvent[];
    onEditEvent: (event: SalesAffectedEvent) => void;
}

export const ForecastEventTable: React.FC<Props> = ({ events, onEditEvent }) => {
    const { mappingRuleById } = useListMappingRules();
    const columns: ColumnsType<SalesAffectedEvent> = [
        {
            key: "name",
            title: "Name",
            dataIndex: "name",
        },
        {
            key: "period",
            title: "Period",
            width: 25,
            render: (_, { start, end }) => {
                return (
                    <>
                        <Tag style={{ width: "100%" }}>
                            Start: {start.format(MomentFormat.dateTimeDisplay)}
                        </Tag>
                        <Tag style={{ width: "100%" }}>
                            End: {end.format(MomentFormat.dateTimeDisplay)}
                        </Tag>
                    </>
                );
            },
        },
        {
            key: "venueId",
            title: "Venue",
            dataIndex: "venueId",
            render: (venueId: string) => mappingRuleById[venueId]?.segment_name,
        },
        {
            key: "areaIds",
            title: "Areas",
            dataIndex: "areaIds",
            render: (areaIds: string[]) => {
                const areas = areaIds.map((id) => mappingRuleById[id]?.segment_name);
                return areas.length > 1 ? (
                    <Popover
                        content={
                            <List
                                size={"small"}
                                dataSource={areas}
                                renderItem={(area) => <List.Item>{area}</List.Item>}
                            />
                        }
                    >
                        <div style={{ cursor: "pointer" }}>{areas[0]}</div>
                    </Popover>
                ) : (
                    <div>{areas[0]}</div>
                );
            },
        },
        {
            key: "classIds",
            title: "Classes",
            dataIndex: "classIds",
            render: (classIds: string[]) => {
                const classes = classIds.map(
                    (id) => mappingRuleById[id]?.segment_name
                );
                return classes.length ? (
                    <Popover
                        content={
                            <List
                                size={"small"}
                                dataSource={classes}
                                renderItem={(className) => (
                                    <List.Item>{className}</List.Item>
                                )}
                            />
                        }
                    >
                        <div style={{ cursor: "pointer" }}>{classes[0]}</div>
                    </Popover>
                ) : (
                    <div>{classes[0]}</div>
                );
            },
        },
        {
            key: "expectedSales",
            title: "Expected Sales",
            render: (_, { expectedSalesAmount, expectedSalesPercent }) =>
                expectedSalesAmount
                    ? prependDollarSymbol(expectedSalesAmount)
                    : `${expectedSalesPercent}%`,
        },
        {
            key: "actions",
            render: (_, event) => {
                return (
                    <Button
                        type={"primary"}
                        onClick={() => onEditEvent(event)}
                        icon={<EditOutlined />}
                        shape={"circle"}
                    />
                );
            },
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={events}
            pagination={{ pageSize: 5, hideOnSinglePage: true }}
        />
    );
};
