import { useGetLastCutOffTime } from "Hooks/useGetLastCutOffTime";
import { useMemo } from "react";
import {
    AggregateFilter,
    ComparisonFilter,
    useAggregateFilter,
    useComparisonFilter,
    useDateRangeFilter,
    useDaysFilter,
} from "Store/filterStore";
import { Maybe } from "types";
import {
    calculateNumberOfDaysAndWeeksSelectedInRange,
    DATE_TIME_FORMAT,
    parseToMoment,
} from "Utils/date-utils";
import { TotalSalesAndStaffHours } from "../../../dashboard-model";
import { getComparisonPeriod } from "../../../dashboard-utils";
import { useGetForecastSalesByDates } from "./useGetForecastSalesByDates";
import { useGetSalesAndStaffHoursByDates } from "./useGetSalesAndStaffHoursByDates";
import { chain } from "lodash";

export const useGetSalesAndStaffHours = () => {
    const {
        selectedDateRange: { start, end },
    } = useDateRangeFilter();
    const { selectedDays } = useDaysFilter();

    const { selectedComparison } = useComparisonFilter();
    const { selectedAggregate } = useAggregateFilter();
    const forecastComparison = selectedComparison === ComparisonFilter.Forecast;
    const { cutOffTime, isLoading: gettingLastCutoffTime } = useGetLastCutOffTime();

    // Note these strange offsets are due to the Quirk of Salesline where each day starts at 6am instead of the usual 12am
    // As it is a better fit for shift based businesses as shifts can finish well after midnight
    const range = {
        start: parseToMoment(start).add(6, "hours").format(DATE_TIME_FORMAT),
        end: parseToMoment(end)
            .add(cutOffTime ? 0 : 1, "day")
            .add(cutOffTime?.hours ?? 5, "hours")
            .add(cutOffTime?.minutes ?? 45, "minutes")
            .format(DATE_TIME_FORMAT),
    };

    // Fetch the baseline sales and staff hours data
    const {
        isLoading: gettingTotalSalesAndStaffHours,
        totalSalesAndStaffHours,
        augmentedMappingRules,
        query,
        totalSalesAndStaffHoursByMappingRule,
    } = useGetSalesAndStaffHoursByDates({
        range,
    });

    const comparisonPeriod = getComparisonPeriod({
        start,
        end,
        selectedComparison,
        cutOffTime,
    });

    const { numberOfWeeksSelected, numberOfDaysSelected } = useMemo(() => {
        return calculateNumberOfDaysAndWeeksSelectedInRange(
            start,
            end,
            selectedDays
        );
    }, [end, selectedDays, start]);

    // Fetch the forecast sales and staff hours data
    const {
        isLoading: gettingForecastedSales,
        forecastSales,
        forecastSalesByMappingRules,
        query: forecastQuery,
    } = useGetForecastSalesByDates({
        range,
        enabled: Boolean(forecastComparison) && !selectedAggregate,
    });

    // Fetch the comparison sales and staff hours data
    const {
        isLoading: gettingTotalSalesAndStaffHoursForComparison,
        totalSalesAndStaffHours: totalSalesAndStaffHoursForComparison,
        totalSalesAndStaffHoursByMappingRule:
            totalSalesAndStaffHoursByMappingRuleForComparison,
        query: comparisonQuery,
    } = useGetSalesAndStaffHoursByDates({
        range: comparisonPeriod ?? range,
        enabled:
            Boolean(comparisonPeriod) && !forecastComparison && !selectedAggregate,
    });

    const averageSalesAndStaffHours: Maybe<TotalSalesAndStaffHours> = useMemo(() => {
        if (!selectedAggregate || !totalSalesAndStaffHours) return;

        const { totalSales, totalStaffHours } = totalSalesAndStaffHours;
        if (selectedAggregate === AggregateFilter.AverageDay) {
            return {
                totalSales: totalSales / numberOfDaysSelected,
                totalStaffHours: totalStaffHours / numberOfDaysSelected,
            };
        }

        return {
            totalSales: totalSales / (numberOfWeeksSelected || 1),
            totalStaffHours: totalStaffHours / (numberOfWeeksSelected || 1),
        };
    }, [
        numberOfDaysSelected,
        numberOfWeeksSelected,
        selectedAggregate,
        totalSalesAndStaffHours,
    ]);

    const averageSalesAndStaffHoursByMappingRule = useMemo(() => {
        if (!selectedAggregate || !totalSalesAndStaffHoursByMappingRule) return;
        const denominator =
            selectedAggregate === AggregateFilter.AverageDay
                ? numberOfDaysSelected
                : numberOfWeeksSelected || 1;

        return Object.keys(totalSalesAndStaffHoursByMappingRule).reduce<
            Record<string, TotalSalesAndStaffHours>
        >((result, mappingRule) => {
            const { totalSales, totalStaffHours } =
                totalSalesAndStaffHoursByMappingRule[mappingRule];
            result[mappingRule] = {
                totalSales: totalSales / denominator,
                totalStaffHours: totalStaffHours / denominator,
            };

            return result;
        }, {});
    }, [
        numberOfDaysSelected,
        numberOfWeeksSelected,
        selectedAggregate,
        totalSalesAndStaffHoursByMappingRule,
    ]);

    // This takes in the local maximum then calculate the total for each segment and
    const unmappedSegmentTotals: {
        [index: string]: {
            transactionTotal: number;
            staffHr: number;
            segmentType: string;
            salesDifference: number;
            staffHrDifference: number;
        };
    } = useMemo(() => {
        if (totalSalesAndStaffHours) {
            const mappingRulesForSegment = chain(augmentedMappingRules)
                .groupBy("segment_type")
                .map((mappingRules, segmentType) => {
                    let transactionTotal = 0;
                    let staffHr = 0;
                    mappingRules.forEach((mappingRule) => {
                        transactionTotal += mappingRule.transactionTotal;
                        staffHr += mappingRule.staffHr;
                    });
                    return {
                        transactionTotal,
                        staffHr,
                        segmentType,
                        salesDifference:
                            totalSalesAndStaffHours.totalSales - transactionTotal,
                        staffHrDifference:
                            totalSalesAndStaffHours.totalStaffHours - staffHr,
                    };
                })
                .filter(
                    (mappingRule) =>
                        mappingRule.transactionTotal !==
                            totalSalesAndStaffHours.totalSales ||
                        mappingRule.staffHr !==
                            totalSalesAndStaffHours.totalStaffHours
                )
                .keyBy("segmentType")
                .value();

            return mappingRulesForSegment;
        }
        return {};
    }, [augmentedMappingRules, totalSalesAndStaffHours]);

    const isLoading =
        gettingTotalSalesAndStaffHours ||
        gettingTotalSalesAndStaffHoursForComparison ||
        gettingForecastedSales ||
        gettingLastCutoffTime;

    return {
        isLoading,
        query,
        totalSalesAndStaffHours,
        totalSalesAndStaffHoursByMappingRule,
        comparisonQuery,
        totalSalesAndStaffHoursForComparison,
        totalSalesAndStaffHoursByMappingRuleForComparison,
        averageSalesAndStaffHours,
        averageSalesAndStaffHoursByMappingRule,
        forecastQuery,
        forecastSales,
        forecastSalesByMappingRules,
        augmentedMappingRules,
        unmappedSegmentTotals,
    };
};
