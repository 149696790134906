import React, { useEffect, useMemo, useState } from "react";
import { Modal, Select } from "antd";
import omit from "lodash/omit";
import { calcTextWidth, mapListValues } from "./SelectWidget";
import { WidgetProps } from "react-awesome-query-builder";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useMultiSelectWidgetWithAPI } from "../MultiSelectWidgetWithAPI";
import { openNotificationWithIcon } from "Components/Notification";
import { ExclamationCircleOutlined } from "@ant-design/icons"; // Add this line

// function hasDuplicateValues(arr: ListValue[]): boolean {
//     const values: string[] = [];
//     for (const item of arr) {
//         if (values.includes(item.value)) {
//             return true;
//         }
//         values.push(item.value);
//     }
//
//     return false;
// }

export type ListValue = {
    value: number;
    title: string;
    disabled?: boolean;
    warning?: boolean;
    count?: number;
};

const Option = Select.Option;

const SELECT_WIDTH_OFFSET_RIGHT = 200;

const MultiSelectWidget = ({
    setValue,
    config,
    value: valueArray,
    field,
    placeholder,
    customProps,
    fieldDefinition,
    readonly,
    listValues,
    allowCustomValues,
    id,
}: WidgetProps & {
    listValues: any;
    allowCustomValues: boolean;
}) => {
    const [options, setOptions] = useState<React.ReactNode>(undefined);
    const [optionsMaxWidth, setOptionsMaxWidth] = useState(0);
    const { initialValue, isLoading } = useMultiSelectWidgetWithAPI({
        currentSectionId: id,
    });
    const [aValue, setAValue] = useState<any[]>([]); // Add this line to create aValue as a state variable

    useEffect(() => {
        let currentOptionsMaxWidth = 0;

        setOptions(
            mapListValues(listValues, ({ title, value, disabled }) => {
                currentOptionsMaxWidth = Math.max(
                    currentOptionsMaxWidth,
                    calcTextWidth(title)
                );

                let isShouldDisable = disabled;
                if (initialValue) {
                    isShouldDisable = !initialValue.includes(value);
                }

                return (
                    <Option key={value} value={value} disabled={isShouldDisable}>
                        {title}
                    </Option>
                );
            })
        );

        setOptionsMaxWidth(currentOptionsMaxWidth);
    }, [listValues, initialValue, isLoading]);

    useEffect(() => {
        const newValue =
            valueArray && valueArray.length ? valueArray : initialValue ?? [];
        setAValue(newValue);
    }, [valueArray, initialValue]);

    const handleChange = (val) => {
        if (val && !val.length) val = undefined; // not allow []

        setValue(val);
        setTimeout(() => {
            setAValue(val); // Update aValue state when handleChange is called
        }, 25);
    };

    const filterOption = (input, option) => {
        let dataForFilter = option.children || option.value;

        if (Array.isArray(dataForFilter)) {
            dataForFilter = dataForFilter.join(" ");
        }
        return dataForFilter?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const { renderSize } = config.settings;
    const placeholderWidth = calcTextWidth(placeholder);
    // const aValue = valueArray && valueArray.length ? valueArray : initialValue ?? [];
    const width = aValue ? null : placeholderWidth + SELECT_WIDTH_OFFSET_RIGHT;
    const dropdownWidth = optionsMaxWidth + SELECT_WIDTH_OFFSET_RIGHT;
    const customSelectProps = omit(customProps, ["showCheckboxes"]);

    const sortedValues = useMemo(
        () =>
            aValue?.sort((itemA, itemB) => {
                const titleA = itemA?.title ?? "";
                const titleB = itemB?.title ?? "";
                return titleA.toString().localeCompare(titleB.toString());
            }) ?? [],
        [aValue]
    );

    if (isLoading) return <div>Loading...</div>;

    // if (hasDuplicateValues(listValues)) {
    //     openNotificationWithIcon({
    //         title: `The field "${fieldDefinition.label}" has duplicate values in its field settings. Please fix this before continuing.`,
    //         type: "error",
    //         key: `duplicate-values-found ${fieldDefinition.label}`,
    //     });
    // }

    return (
        <Select
            disabled={readonly}
            mode={allowCustomValues ? "tags" : "multiple"}
            style={{
                minWidth: width ?? 350,
                width: width ?? 350,
            }}
            dropdownStyle={{
                width: dropdownWidth,
            }}
            key={"widget-multiselect"}
            dropdownMatchSelectWidth={false}
            placeholder={placeholder}
            size={(renderSize as SizeType) || "middle"}
            value={sortedValues}
            onChange={handleChange}
            filterOption={filterOption}
            {...customSelectProps}
        >
            {options}
        </Select>
    );
};

export { MultiSelectWidget };
