import moment from "moment";
import React, { ReactElement, useContext, useState } from "react";
import { DatePicker, Tag } from "antd";
import { Context, Filters, SelectedDates } from "../../State/store";
import styled from "styled-components";
import { UpdateFiltersAction } from "../../State/actions";
import { formatForDisplay } from "Utils/date-utils";
import { Nullable } from "types";
import { useDateRangeFilter } from "Store/filterStore";
import { usePreviewMode } from "Store/viewSettingsStore";

const { RangePicker } = DatePicker;

export const FullWidthDatePicker = styled(RangePicker)`
    width: 100%;
`;

const DatePickerList = styled.ul`
    margin-bottom: 0;
    padding: 4px 12px;
    overflow: hidden;
    line-height: 34px;
    text-align: left;
    list-style: none;
`;

const DatePickerListItem = styled.li`
    display: inline-block;
`;

const DatePickerTag = styled(Tag)`
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    cursor: pointer;
`;

const convertSelectedDatesIntoMoment = (
    selectedDates: SelectedDates
): [moment.Moment, moment.Moment] => {
    if (selectedDates.isPresetDateRange) {
        const selectedRange = presetRanges.find(
            (range) => range.label === selectedDates.presetDateRangeLabel
        )!;

        return selectedRange.range();
    }

    return [moment(selectedDates.fromDate), moment(selectedDates.toDate)];
};

interface PresetRange {
    label: string;
    range: () => [moment.Moment, moment.Moment];
}

export const presetRanges: PresetRange[] = [
    {
        label: "Today",
        range: () => [moment(), moment()],
    },
    {
        label: "Yesterday",
        range: () => [moment().subtract(1, "d"), moment().subtract(1, "d")],
    },
    {
        label: "This Week",
        range: () => [moment().startOf("isoWeek"), moment()],
    },
    {
        label: "Last Week",
        range: () => [
            moment().subtract(1, "w").startOf("isoWeek"),
            moment().subtract(1, "w").endOf("isoWeek"),
        ],
    },
    {
        label: "This Month",
        range: () => [moment().startOf("month"), moment()],
    },
    {
        label: "Last Month",
        range: () => [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
        ],
    },
    {
        label: "This Year",
        range: () => [moment().startOf("year"), moment()],
    },
    {
        label: "Last Year",
        range: () => [
            moment().subtract(1, "y").startOf("year"),
            moment().subtract(1, "y").endOf("year"),
        ],
    },
    {
        label: "Forever",
        range: () => [moment("2018-01-01"), moment()],
    },
];

// ! This being kept here for when we re-enable react-dates on Desktop view

// const StyledReactDatesWrapper = styled.div`
//     .DateRangePickerInput,
//     .DateRangePicker {
//         .DateInput {
//             width: 108px !important;
//         }
//         .DateInput_input {
//             font-size: 14px !important;
//             line-height: 12px !important;
//         }
//         .DateInput_input__focused {
//             border-bottom: 2px solid ${WRANGLR_LIGHT_BLUE} !important;
//         }
//     }
// `;

export default function DateFilter(): ReactElement {
    const [state, dispatch] = useContext(Context);
    const { filters } = state;

    const [currentMode, setCurrentMode] = useState<any>(["date", "date"]);

    const [open, setOpen] = useState(false);

    const { selectedComparison, mode } = filters;
    const { selectedDateRange, updateDateRangeFilter } = useDateRangeFilter();

    const disabledDate = (current) => {
        return (
            current &&
            (current > moment().endOf("day") || current < moment("2018-01-01"))
        );
    };

    const onPanelChange = (value, mode) => {
        if (mode[0] === "decade") {
            mode[0] = "year";
        }

        setCurrentMode(mode);
    };

    const onChange = (dates: Nullable<[moment.Moment, moment.Moment]>) => {
        const today = formatForDisplay(undefined, "YYYY-MM-DD");

        const fromDate = dates
            ? formatForDisplay(dates[0].toString(), "YYYY-MM-DD")
            : today;
        const toDate = dates
            ? formatForDisplay(dates[1].toString(), "YYYY-MM-DD")
            : today;

        const action: UpdateFiltersAction = {
            type: "UPDATE_FILTERS",
            payload: {
                filters: {
                    ...state.filters,
                    selectedDates: {
                        fromDate,
                        toDate,
                        isPresetDateRange: false,
                    },
                    mode:
                        selectedComparison !== null && mode === "forecast"
                            ? mode
                            : "actual",
                } as Filters,
            },
        };
        dispatch(action);
        setCurrentMode(["date", "date"]);
        updateDateRangeFilter({ start: fromDate, end: toDate });
    };

    const onClickPresetRange = (e) => {
        const target = e.target as HTMLElement;
        const rangeLabel = target.innerText;
        const selectedRange = presetRanges.find(
            (range) => range.label === rangeLabel
        )!;
        const [fromDateObj, toDateObj] = selectedRange.range();
        const fromDate = fromDateObj.format("YYYY-MM-DD");
        const toDate = toDateObj.format("YYYY-MM-DD");
        const action: UpdateFiltersAction = {
            type: "UPDATE_FILTERS",
            payload: {
                filters: {
                    ...state.filters,
                    selectedDates: {
                        fromDate,
                        toDate,
                        isPresetDateRange: true,
                        presetDateRangeLabel: selectedRange.label,
                    },
                    mode:
                        selectedComparison !== null && mode === "forecast"
                            ? mode
                            : "actual",
                } as Filters,
            },
        };
        updateDateRangeFilter({ start: fromDate, end: toDate });
        dispatch(action);
        setOpen(false);
        setCurrentMode(["date", "date"]);
    };

    return (
        <FullWidthDatePicker
            mode={currentMode}
            disabledDate={disabledDate}
            onPanelChange={onPanelChange}
            onChange={onChange as any}
            open={open}
            onOpenChange={() => setOpen(!open)}
            value={[moment(selectedDateRange.start), moment(selectedDateRange.end)]}
            format={["DD-MM-YYYY", "DD-MM-YYYY"]}
            renderExtraFooter={() => DatePickerFooter(onClickPresetRange)}
        />
    );
}

function DatePickerFooter(onClickPresetRange: (e: any) => void) {
    return (
        <>
            <DatePickerList>
                {presetRanges.map(({ label }, index) => (
                    <DatePickerListItem key={label} onClick={onClickPresetRange}>
                        <DatePickerTag>{label}</DatePickerTag>
                    </DatePickerListItem>
                ))}
            </DatePickerList>
        </>
    );
}
