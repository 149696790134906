import moment from "moment";
import {
    APEX_CHARTS_GRAY,
    MOBILE_VIEWPORT_WIDTH,
    QUANTACO_CHART_DARK_ORANGE,
    QUANTACO_CHART_LIGHT_BLUE,
} from "./constants";
import { Filters } from "../State/store";
import { Granularity, SeriesRow } from "../Api/Cube/types";
import { ResultSet } from "@cubejs-client/core";

import { DAYS_OF_WEEK } from "./types";
import { ApexOptions } from "apexcharts";
import { QTCDate } from "./date-utils";

/**
 * General utility functions should go here.
 */
const calculateWeekTooltipString = (
    val: string | number,
    parsedDateObject: moment.Moment | undefined
): string => {
    const curWeek = moment(val);
    const endOfWeek = moment(val).add(7, "days");
    if (parsedDateObject) {
        if (endOfWeek.isAfter(parsedDateObject))
            return (
                curWeek.format("D MMM YYYY") +
                " to " +
                parsedDateObject.format("D MMM YYYY") +
                " (Week " +
                curWeek.format("w") +
                ")"
            );
    }
    return (
        curWeek.format("D MMM YYYY") +
        " to " +
        endOfWeek.format("D MMM YYYY") +
        " (Week " +
        curWeek.format("w") +
        ")"
    );
};

const getStrokeWidthBasedOnGranularity = (granularity: Granularity): number => {
    switch (granularity) {
        case "minute":
            return 5;
        case "day":
            return 5;
        case "hour":
            return 3;
        case "week":
            return 5;
        case "month":
            return 5;
    }
};

export const formatXAxisTimestampBasedOnGranularity = (
    granularity: Granularity,
    val: string
): string => {
    switch (granularity) {
        case "minute":
            return moment(val).format("HH:mm");
        case "hour":
            return moment(val).format("dddd HH:00");
        case "day":
            return moment(val).format("ddd, MMM Do");
        case "week":
            return " Week " + moment(val).format("w (YYYY)");
        case "month":
            return moment(val).format("MMMM YYYY");
    }
};

/**
 *
 *
 * @param granularity
 * @param val
 * @param addEndTimestamp
 * @param endDate Only applies to "week" granularity
 * @returns
 */
export const formatTooltipTimestampBasedOnGranularity = (
    granularity: Granularity,
    val: string | number,
    addEndTimestamp = false,
    endDate?: string
) => {
    const parsedDateObject = endDate ? moment(endDate) : undefined;
    switch (granularity) {
        case "minute":
            if (addEndTimestamp)
                return `${moment(val).format("HH:mm")} - ${moment(val)
                    .add(15, "minutes")
                    .format("HH:mm")}`;
            return moment(val).format("HH:mm");

        case "hour":
            if (addEndTimestamp)
                return `${moment(val).format("ddd HH:mm")} - ${moment(val)
                    .add(1, "hour")
                    .format("ddd HH:mm")}`;
            return moment(val).format("ddd HH:mm");

        case "day":
            return moment(val).format("dddd, MMMM Do");

        case "week":
            return calculateWeekTooltipString(val, parsedDateObject);
        case "month":
            return moment(val).format("MMMM YYYY");
    }
};

export function currencyFormatter(num: number, fractionDigits = 1): string {
    return "$" + numFormatter(num, fractionDigits);
}

export const getCumulativeTransactionsLineGraphOptions = (
    xAxis: string[],
    granularity: Granularity
): ApexOptions => {
    return {
        chart: {
            id: "basic-line",
            toolbar: {
                tools: {
                    download: false,
                    pan: false,
                    zoomin: false,
                    zoomout: false,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            type: "datetime",
            categories: xAxis,
            labels: {
                datetimeUTC: false,
                formatter: (val) => {
                    return formatXAxisTimestampBasedOnGranularity(granularity, val);
                },
            },
        },
        tooltip: {
            x: {
                formatter: (val) =>
                    formatTooltipTimestampBasedOnGranularity(granularity, val, true),
            },
        },
        colors: [QUANTACO_CHART_DARK_ORANGE, APEX_CHARTS_GRAY],
        yaxis: {
            tickAmount: 4,
            min: 0,
            labels: {
                formatter: (num) => {
                    return currencyFormatter(num);
                },
            },
            forceNiceScale: true,
        },
        grid: {
            show: true,
        },
        stroke: {
            width: window.innerWidth <= MOBILE_VIEWPORT_WIDTH ? 2 : 5,
            curve: "straight",
        },
    };
};

/**
 *
 * Formats large number into a more readable format.
 *
 * @param num
 * @param fractionDigits
 * @returns
 */
export function numFormatter(num: number, fractionDigits = 1): string {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(fractionDigits) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(fractionDigits) + "M"; // convert to M for number from > 1 million
    } else {
        return (
            Math.round(num * 10 ** fractionDigits) /
            10 ** fractionDigits
        ).toString(); // if value < 1000, nothing to do
    }
}

export const getSalesOverTimeLineGraphOptions = (
    xAxis: string[],
    granularity: Granularity,
    endDate?: string
): ApexOptions => {
    const chartOptions: ApexOptions = {
        chart: {
            id: "basic-line",
            toolbar: {
                offsetX: -20,
                tools: {
                    download: false,
                    pan: false,
                    zoomin: false,
                    zoomout: false,
                },
            },
        },

        tooltip: {
            x: {
                formatter: (val) =>
                    formatTooltipTimestampBasedOnGranularity(
                        granularity,
                        val,
                        true,
                        endDate
                    ),
            },
        },
        yaxis: [
            {
                tickAmount: 4,
                min: 0,
                labels: {
                    formatter: (num) => {
                        return currencyFormatter(num);
                    },
                },
                forceNiceScale: true,
            },
            {
                opposite: true,
                tickAmount: 4,
                min: 0,
                labels: {
                    formatter: (num) => {
                        return numFormatter(num);
                    },
                },
            },
        ],
        stroke: {
            curve: ["smooth", "smooth", "stepline"],
            width: [
                window.innerWidth <= MOBILE_VIEWPORT_WIDTH
                    ? 2
                    : getStrokeWidthBasedOnGranularity(granularity) + 0.5,
                window.innerWidth <= MOBILE_VIEWPORT_WIDTH
                    ? 2
                    : getStrokeWidthBasedOnGranularity(granularity),
                3,
            ],
        },
        colors: [QUANTACO_CHART_DARK_ORANGE, QUANTACO_CHART_LIGHT_BLUE],
        fill: {
            opacity: [1, 0.7],
            type: "solid",
            gradient: {
                opacityFrom: 1,
                opacityTo: 1,
            },
        },
    };

    if (granularity === "minute" || granularity === "hour") {
        chartOptions["xaxis"] = {
            type: "datetime",
            categories: xAxis,
            labels: {
                datetimeUTC: false,
                formatter: undefined,
            },
        };
    } else {
        chartOptions["xaxis"] = {
            type: "datetime",
            categories: xAxis,
            labels: {
                datetimeUTC: false,
                formatter: (val) => {
                    return formatXAxisTimestampBasedOnGranularity(granularity, val);
                },
            },
        };
    }
    return chartOptions;
};

const epsilon = 0.001;

export const calculatePercentageDifference = (
    originalValue: number,
    comparisonValue: number
): number | undefined => {
    // Check for invalid input values
    if (
        originalValue === null ||
        originalValue === undefined ||
        isNaN(originalValue) ||
        comparisonValue === null ||
        comparisonValue === undefined ||
        isNaN(comparisonValue)
    ) {
        return undefined;
    }

    // Check if both values are very close to zero
    const epsilon = 0.0001; // assuming this is a very small number
    if (Math.abs(originalValue) < epsilon && Math.abs(comparisonValue) < epsilon) {
        return 0;
    }

    // Check if the comparisonValue is close to zero
    if (Math.abs(comparisonValue) < epsilon) {
        return undefined;
    }

    // Calculate the percentage difference
    const result =
        ((originalValue - comparisonValue) / Math.abs(comparisonValue)) * 100;

    // Limit the result precision to 2 decimal places
    return Number(result.toFixed(2));
};

export const mapByAggregatePeriod = (
    data: SeriesRow[],
    medianLabel: string,
    valuesToMap: string[],
    valueLabels: string[]
): any => {
    const dict = {};
    data.forEach((row: any) => {
        const sortablePeriodMedian = row[medianLabel];
        if (dict[sortablePeriodMedian]) {
            valueLabels.forEach((label, index) => {
                const value = row[valuesToMap[index]];
                dict[sortablePeriodMedian][label].push(value);
            });
        } else {
            dict[sortablePeriodMedian] = {};
            valueLabels.forEach((label, index) => {
                const value = row[valuesToMap[index]];
                dict[sortablePeriodMedian][label] = [value];
            });
        }
    });
    return dict;
};

export const getMedian = (arr: number[]): number => {
    arr.sort();
    const len = arr.length;
    const mid = Math.ceil(len / 2);
    return len % 2 == 0 ? (arr[mid] + arr[mid - 1]) / 2 : arr[mid - 1];
};

export const calculateAggregateResultSet = (
    filters: Filters,
    resultSet: ResultSet,
    medianLabel: string,
    valuesToMap: string[],
    valueLabels: string[]
): any => {
    const dict = mapByAggregatePeriod(
        resultSet.rawData(),
        medianLabel,
        valuesToMap,
        valueLabels
    );
    const xAxis = Object.keys(dict);
    const series: any = [valueLabels.map(() => [])];
    xAxis.forEach((x) => {
        valueLabels.forEach((label, index) => {
            const median = getMedian(dict[x][label]);
            series[0][index].push(median);
        });
    });
    return {
        xAxis: xAxis.map(
            (val) =>
                moment().subtract(1, "d").format("YYYY") +
                moment(val).format("-MM-DDTHH:mm:ss.SSS")
        ),
        series,
    };
};

export const stringSort = (a: string, b: string): number => {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
};

export const datetimeSort = (
    a: moment.Moment | string,
    b: moment.Moment | string
): number => {
    let aDateObj = a as moment.Moment;
    let bDateObj = b as moment.Moment;
    if (typeof a === "string") {
        aDateObj = moment(a);
    }
    if (typeof b === "string") {
        bDateObj = moment(b);
    }

    if (aDateObj.isBefore(bDateObj)) {
        return -1;
    }
    if (aDateObj.isAfter(bDateObj)) {
        return 1;
    }
    return 0;
};

/**
 * Helper function to wrap a string in double-sided brackets.
 *
 * @param input
 * @returns
 */
export const bracketStringWrapping = (input: string): string => `\x00 (${input})`; // Note: the \x00 seems hacky but it is the make sure the spaces aren't auto trimmed.

export const isNumber = (value: number | string | undefined): boolean => {
    return typeof value === "number" && isFinite(value);
};

export const sortNumbersWithPossibleEmpty = (
    a: number | string,
    b: number | string
): number => {
    if (isNumber(a) && isNumber(b)) {
        return (a as number) - (b as number);
    } else if (!isNumber(a) && isNumber(b)) {
        return -1;
    } else if (!isNumber(b) && isNumber(a)) {
        return 1;
    } else {
        return 0;
    }
};

export const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

// ENUM for converting string values of Days into expected NUM value for Cube.js
export enum DAYS_OF_WEEK_NUM_VALUE {
    "Monday" = "1",
    "Tuesday" = "2",
    "Wednesday" = "3",
    "Thursday" = "4",
    "Friday" = "5",
    "Saturday" = "6",
    "Sunday" = "7",
}

export const DAYS_OF_THE_WEEK: DAYS_OF_WEEK[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

export const getShiftDate = (date: QTCDate) => {
    if (date.hours() < 6) {
        return date.clone().subtract(1, "day").format("dddd");
    }
    return date.format("dddd");
};

export const displayTwoTimePeriods = (
    start: string | Date,
    end: string | Date
): string => {
    if (moment(start).date() == moment(end).date()) {
        return `${moment(start).format("DD/MM/YYYY HH:mm")} - ${moment(end).format(
            "HH:mm"
        )} `;
    } else {
        return `${moment(start).format("DD/MM/YYYY HH:mm")} - ${moment(end).format(
            "DD/MM/YYYY HH:mm"
        )} `;
    }
};
export const parseFullName = (fullName: string): string[] => {
    const processedFullName = fullName.trim();
    if (!processedFullName.length) return [];
    let [firstName, lastName] = processedFullName.split(" ");
    firstName = firstName
        ? firstName.charAt(0).toUpperCase() + firstName.slice(1)
        : " ";
    lastName = lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : " ";
    return [firstName, lastName];
};

export const parseName = (
    firstName: string,
    lastName: string
): string | undefined => {
    if (!firstName.trim() || !lastName.trim()) {
        console.log("Error,name empty");
        return;
    } else
        return `${firstName.trim().charAt(0).toUpperCase()}${firstName.slice(
            1
        )} ${lastName.trim().charAt(0).toUpperCase()}${lastName.slice(1)}`;
};
