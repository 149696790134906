import {
    createMappingRule,
    deleteMappingRule,
    IntegrationSource,
    listIntegrationSources,
    listSegmentsByIntegrationSource,
    MappingRule,
    SegmentWithIntegration,
    updateMappingRule,
} from "Api/mappingConfigurator";
import {
    fetchAllSegments,
    fetchMappingRuleById,
    fetchMappingRules,
    MappingRuleV2,
} from "Api/mappingConfiguratorV2";
import { convertNumericalIdsToPseudoUniqueIds } from "Pages/Dashboard/Components/SegmentTable/Hooks/useGetSalesAndStaffHoursByDates";
import { convertUnmappedSegmentResponseToSegments } from "Pages/MappingConfigurator/MappingConfiguratorEditorPage.logic";
import { chain } from "lodash";
import { useMemo } from "react";
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

type MappingRuleID = string;

export const useGetMappingRuleById = (
    id: string
): UseQueryResult<MappingRuleV2, any> => {
    const queryKey = ["MAPPING_RULE", id];
    return useQuery(queryKey, () => fetchMappingRuleById(id), {
        staleTime: Infinity,
        retry: false,
    });
};

export const useListSegmentsByIntegrationSource = (
    integrationSource?: string
): UseQueryResult<SegmentWithIntegration[], any> => {
    const queryResult = useQuery(
        ["SEGMENTS", integrationSource],
        () => listSegmentsByIntegrationSource(integrationSource),
        {
            staleTime: Infinity,
            enabled: Boolean(integrationSource),
            select: (result) => {
                return result.map(
                    ({
                        segment_id: segmentId,
                        segment_type: segmentType,
                        segment_value: segmentName,
                        integration_name: integrationName,
                        parent_segment_id: parentSegmentId,
                        service_id: serviceId,
                    }) => ({
                        segmentId,
                        segmentType,
                        segmentName,
                        integrationName,
                        parentSegmentId,
                        serviceId,
                    })
                );
            },
        }
    );
    return queryResult;
};

export const useListIntegrationSources = (): UseQueryResult<
    IntegrationSource[],
    any
> => {
    const queryResult = useQuery("INTEGRATION_SERVICES", listIntegrationSources, {
        staleTime: Infinity,
        select: (result) => {
            return result.map(
                ({
                    integration_id: integrationId,
                    integration_name: integrationName,
                    services,
                }) => ({
                    integrationId,
                    integrationName,
                    services: services,
                })
            );
        },
    });

    return queryResult;
};

export const useListMappingRules = (): {
    data?: MappingRuleV2[];
    isLoading: boolean;
    refetch: () => void;
    mappingRuleById: Record<MappingRuleID, MappingRuleV2>;
    mappingRuleBySegmentName: Record<string, MappingRuleV2>;
} => {
    const { data: segments, isLoading: v2SegmentsLoading } = useQuery(
        "V2_ALL_SEGMENTS",
        fetchAllSegments,
        {
            staleTime: Infinity,
            select: (result) => {
                if (!result) return [];

                return convertUnmappedSegmentResponseToSegments(result);
            },
        }
    );
    const { data, isLoading, refetch } = useQuery(
        "MAPPING_RULES",
        fetchMappingRules,
        {
            select(data) {
                if (segments === undefined) return undefined;

                // return {
                //     ...mappingRule,
                //     result: mappingRule.result.map((mappingRuleResult) => {
                //         // deputy doesn't have class name
                //         return ["deputy", "tanda"].includes(
                //             mappingRuleResult.integrationSource
                //         )
                //             ? {
                //                   ...mappingRuleResult,
                //                   areaIds: undefined,
                //                   classIds: mappingRuleResult.areaIds,
                //               }
                //             : mappingRuleResult;
                //     }),
                // };
                // }),
                return data.map((rule) => {
                    return {
                        ...rule,
                        id: rule.uuid,
                        result: convertNumericalIdsToPseudoUniqueIds(
                            rule.result,
                            segments
                        ),
                    };
                });
            },
            enabled: !v2SegmentsLoading && Boolean(segments),
        }
    );

    const mappingRuleById = useMemo(
        () => chain(data).keyBy("uuid").mapValues().value(),
        [data]
    );

    const mappingRuleBySegmentName = useMemo(
        () => chain(data).keyBy("segment_name").mapValues().value(),
        [data]
    );

    return { isLoading, data, refetch, mappingRuleById, mappingRuleBySegmentName };
};

export const useCreateMappingRule = (): UseMutationResult<
    void,
    any,
    MappingRule
> => {
    const queryClient = useQueryClient();

    return useMutation<void, any, MappingRule>(createMappingRule, {
        onSettled: (data: any, err: any, mappingRule: MappingRule) => {
            queryClient.setQueryData(["MAPPING_RULE", mappingRule.id], mappingRule);
        },
    });
};

export const useDeleteMappingRule = (): UseMutationResult<
    void,
    any,
    MappingRuleID
> => {
    const queryClient = useQueryClient();

    return useMutation<void, any, MappingRuleID>(deleteMappingRule, {
        onSettled: (data: any, err: any, mappingRuleID: string) => {
            queryClient.setQueryData(["MAPPING_RULE", mappingRuleID], mappingRuleID);
        },
    });
};

export const useUpdateMappingRule = (): UseMutationResult<
    void,
    any,
    MappingRule
> => {
    const queryClient = useQueryClient();

    return useMutation<void, any, MappingRule>(updateMappingRule, {
        onSettled: (data: any, err: any, mappingRule: MappingRule) => {
            queryClient.setQueryData(["MAPPING_RULE", mappingRule.id], mappingRule);
        },
    });
};
