import { useMemo } from "react";
import { SaleAndStaffHoursBySegment } from "../../../dashboard-model";
import { RemoteSegment } from "Api/mappingConfigurator";
import { MappingRuleV2 } from "Api/mappingConfiguratorV2";

export interface Data {
    uuid: string;
    unmapped_segments: RemoteSegment[];
}

export interface DataObject {
    activeStaff: number;
    areaName: string;
    areaId: number;
    className: string;
    venueName: string;
    venueId: number;
    serviceId: number;
    transactionTotal: number;
}

export type ExtendedMappingRule = MappingRuleV2 & {
    parsedValues: DataObject[];
    transactionTotal: number;
    staffHr: number;
};

/**
 * @function isSegmentIncluded
 * @description This function checks if a segment is included in the selected options.
 * @param {string} segmentKey - The unique key of the segment.
 * @param {Record<string, string>} mappingRuleBySegmentId - The mapping rule by segment ID.
 * @param {string[]} [selectedOptions] - The selected options.
 * @returns {boolean} Returns true if the segment is included, otherwise false.
 */
const isSegmentIncluded = (
    segmentKey: string,
    mappingRuleBySegmentId: Record<string, string>,
    selectedOptions?: string[]
): boolean =>
    !selectedOptions ||
    selectedOptions.length === 0 ||
    selectedOptions.includes(mappingRuleBySegmentId[segmentKey]);

/**
@function findMatchingSegments
@description This function finds the matching segments for a given mapping rule.
@param {RemoteSegment[]} mappingRuleSegments - The mapping rule segments.
@param {DataObject[]} saleAndStaffHoursBySegment - The sale and staff hours by segment.
@param {Record<string, string>} mappingRuleBySegmentId - The mapping rule by segment ID.
@param {string[]} [selectedVenues] - The selected venues.
@param {string[]} [selectedAreas] - The selected areas.
@param {string[]} [selectedClasses] - The selected classes.
@returns {Object} Returns an object containing the matches, transaction total, staff hours, and active staff.
*/
export const findMatchingSegments = (
    mappingRuleSegments: RemoteSegment[],
    saleAndStaffHoursBySegment: DataObject[],
    mappingRuleBySegmentId: Record<string, string>,
    selectedVenues?: string[],
    selectedAreas?: string[],
    selectedClasses?: string[]
): {
    matches: DataObject[];
    transactionTotal: number;
    staffHr: number;
    activeStaff: number;
    totalSegments: DataObject[];
    mappedSegments: DataObject[];
} =>
    populateMatchesAndTotals(
        createSegmentMap(mappingRuleSegments),
        saleAndStaffHoursBySegment,
        mappingRuleBySegmentId,
        selectedVenues,
        selectedAreas,
        selectedClasses
    );

/**
 * @function createSegmentMap
 * @description This function creates a map of segments for efficient lookups.
 * @param {RemoteSegment[]} mappingRuleSegments - The mapping rule segments.
 * @returns {Map<string, RemoteSegment>} Returns a map of segments.
 */
const createSegmentMap = (
    mappingRuleSegments: RemoteSegment[]
): Map<string, RemoteSegment> =>
    mappingRuleSegments.reduce((segmentMap, segment) => {
        const key = `${segment.segment_type}-${
            segment.segment_type === "class"
                ? segment.segment_value
                : segment.segment_id
        }-${segment.service_id}`;
        return segmentMap.set(key, segment);
    }, new Map());

/**
 * @function populateMatchesAndTotals
 * @description This function populates the matches and calculates the totals.
 * Pseudocode:
 * - Initialize the total transaction and staff hour sums
 * - Iterate over the sale and staff hours by segment
 * - For each dataObject, generate keys for the venue, area, and class
 * - If the segment map has any of these keys, check if the segment is included in the selected options
 * - If the segment is included, check if there's an existing entry in the accumulated dataObject map
 * - If there is, update the transaction total and active staff. If not, create a new entry
 * - Add the transaction total and active staff to the sums
 * - Finally, return the matches and sums
 * @param {Map<string, RemoteSegment>} segmentMap - The map of segments.
 * @param {DataObject[]} saleAndStaffHoursBySegment - The sale and staff hours by segment.
 * @param {Record<string, string>} mappingRuleBySegmentId - The mapping rule by segment ID.
 * @param {string[]} [selectedVenues] - The selected venues.
 * @param {string[]} [selectedAreas] - The selected areas.
 * @param {string[]} [selectedClasses] - The selected classes.
@returns {Object} Returns an object containing the matches, transaction total, staff hours, and active staff.
*/
const populateMatchesAndTotals = (
    segmentMap: Map<string, RemoteSegment>,
    saleAndStaffHoursBySegment: DataObject[],
    mappingRuleBySegmentId: Record<string, string>,
    selectedVenues?: string[],
    selectedAreas?: string[],
    selectedClasses?: string[]
): {
    matches: DataObject[];
    transactionTotal: number;
    staffHr: number;
    activeStaff: number;
    totalSegments: DataObject[];
    mappedSegments: DataObject[];
} => {
    // Initialization of variables to hold total transaction and staff hours sums
    let transactionTotalSum = 0;
    let staffHrSum = 0;

    const totalSegments: DataObject[] = [];
    const mappedSegments: DataObject[] = [];

    // Data object map to hold the matches
    const dataObjectMap = saleAndStaffHoursBySegment.reduce((acc, dataObject) => {
        // Extracting necessary data from the data object
        const {
            serviceId,
            venueId,
            areaId,
            className,
            transactionTotal,
            activeStaff,
        } = dataObject;

        // Generating keys for the venue, area, and class
        const venueKey = `venue-${venueId}-${serviceId}`;
        const areaKey = `area-${areaId}-${serviceId}`;
        const classKey = `class-${className}-${serviceId}`;
        totalSegments.push(dataObject);

        // Checking if the segment map has any of the keys
        if (
            segmentMap.has(venueKey) ||
            segmentMap.has(areaKey) ||
            segmentMap.has(classKey)
        ) {
            mappedSegments.push(dataObject);
            // Checking if the segment is included in the selected options
            if (
                isSegmentIncluded(
                    venueKey,
                    mappingRuleBySegmentId,
                    selectedVenues
                ) &&
                isSegmentIncluded(areaKey, mappingRuleBySegmentId, selectedAreas) &&
                isSegmentIncluded(classKey, mappingRuleBySegmentId, selectedClasses)
            ) {
                // Generating a unique key for the data object
                const key = `${serviceId}-${venueId}-${areaId}-${className}`;
                const existing = acc.get(key);

                // Checking if there's an existing entry in the accumulated data object map
                if (existing) {
                    // Updating the existing entry
                    existing.transactionTotal += transactionTotal;
                    existing.activeStaff += activeStaff;
                } else {
                    // Creating a new entry
                    acc.set(key, { ...dataObject, activeStaff });
                }

                // Adding to the total transaction and staff hours sums
                transactionTotalSum += transactionTotal;
                staffHrSum += activeStaff;
            }
        }
        // Returning the accumulated data object map
        return acc;
    }, new Map());

    // Returning the matches and sums
    return {
        matches: Array.from(dataObjectMap.values()),
        transactionTotal: transactionTotalSum,
        staffHr: staffHrSum,
        activeStaff: staffHrSum,
        totalSegments,
        mappedSegments,
    };
};

/**
 * @function useMergeMappingRulesAndData
 * @description This React hook merges mapping rules and data.
 * Pseudocode:
 * - If either the converted mapping rule result or the sale and staff hours by segment is not provided, return empty arrays
 * - For each mapping rule in the converted mapping rule result, find the matching segments and append the matches and totals to the mapping rule
 * - Create a new mapping rule array with these extended mapping rules
 * - Also calculate the total sales and staff hours by mapping rule
 * - Finally, return the new mapping rules and the total sales and staff hours by mapping rule
 * @param {Record<string, string>} mappingRuleBySegmentId - The mapping rule by segment ID.
 * @param {MappingRuleV2[]} [convertedMappingRuleResult] - The converted mapping rule result.
 * @param {SaleAndStaffHoursBySegment[]} [saleAndStaffHoursBySegment] - The sale and staff hours by segment.
 * @param {string[]} [selectedAreas] - The selected areas.
 * @param {string[]} [selectedClasses] - The selected classes.
 * @param {string[]} [selectedVenues] - The selected venues.
 * @returns {Object} Returns an object containing the new mapping rules and the total sales and staff hours by mapping rule.
 */
export const useMergeMappingRulesAndData = (
    mappingRuleBySegmentId: Record<string, string>,
    convertedMappingRuleResult?: MappingRuleV2[],
    saleAndStaffHoursBySegment?: SaleAndStaffHoursBySegment[] | undefined,
    selectedAreas?: string[],
    selectedClasses?: string[],
    selectedVenues?: string[]
): {
    newMappingRules: ExtendedMappingRule[];
    totalSalesAndStaffHoursByMappingRule: {
        [id: string]: { totalSales: number; totalStaffHours: number };
    };
} =>
    useMemo(() => {
        // If either the converted mapping rule result or the sale and staff hours by segment is not provided, return empty arrays
        if (!convertedMappingRuleResult || !saleAndStaffHoursBySegment) {
            return {
                newMappingRules: [],
                totalSalesAndStaffHoursByMappingRule: {},
            };
        }
        const uniqueSegments = new Set<DataObject>();
        const totalMappedSegments = new Set<DataObject>();

        // For each mapping rule in the converted mapping rule result
        const newMappingRules = convertedMappingRuleResult.map((data) => {
            // Find the matching segments
            const {
                matches,
                transactionTotal,
                staffHr,
                activeStaff,
                totalSegments,
                mappedSegments,
            } = findMatchingSegments(
                data.unmapped_segments,
                saleAndStaffHoursBySegment,
                mappingRuleBySegmentId,
                selectedVenues,
                selectedAreas,
                selectedClasses
            );
            totalSegments.forEach((segment) => uniqueSegments.add(segment));
            mappedSegments.forEach((segment) => totalMappedSegments.add(segment));
            // Return the extended mapping rule with the matches and totals
            return {
                ...data,
                parsedValues: matches,
                transactionTotal,
                staffHr,
                activeStaff,
            };
        });

        // Calculate the total sales and staff hours by mapping rule
        const totalSalesAndStaffHoursByMappingRule: {
            [id: string]: { totalSales: number; totalStaffHours: number };
        } = newMappingRules.reduce((acc, mappingRule) => {
            const { id, transactionTotal, staffHr } = mappingRule;
            acc[id] = acc[id] || { totalSales: 0, totalStaffHours: 0 };
            acc[id].totalSales += transactionTotal;
            acc[id].totalStaffHours += staffHr;
            return acc;
        }, {});

        // console.log("Total unique segments: ", uniqueSegments.size);
        // console.log("Mapped segments: ", totalMappedSegments.size);
        // // TODO In future alert us that there is an unmapped segment!
        // console.log(
        //     "Unmapped segments: ",
        //     uniqueSegments.size - totalMappedSegments.size
        // );
        // console.log({ totalSalesAndStaffHoursByMappingRule, unmappedSegments });

        // Return the new mapping rules and the total sales and staff hours by mapping rule
        return {
            newMappingRules,
            totalSalesAndStaffHoursByMappingRule,
        };
    }, [convertedMappingRuleResult, saleAndStaffHoursBySegment]);
