import React, { ReactElement, useEffect, useState } from "react";
import {
    BaselineMappedRow,
    BaselineRawRow,
    groupDataByField,
} from "./BaselineChart";
import { Col, Row } from "antd";
import SegmentTable from "./SegmentTable";
import moment from "moment";
import { RoundedCard } from "../HomeDashboardDesktop";
import { filterBaselineDataIfRequired, FilteredSegments } from "./index";
import { DAYS_OF_THE_WEEK, getShiftDate } from "../../Utils/utils";
import _ from "lodash";
import { animated, config, useSpring, useTransition } from "react-spring";
import { Spin, Typography } from "antd";
import Table from "antd/lib/table";
import styled from "styled-components";
import { WRANGLR_DARK_BLUE } from "Utils/constants";

const PaddedTableTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
    padding-left: 5px;
    padding-top: 5px;
`;

interface Props {
    baselineData: BaselineRawRow[];
    loading: boolean;
    mode: "baseline" | "drivers";
    setFilteredSegments: React.Dispatch<React.SetStateAction<FilteredSegments>>;
    filteredSegments: FilteredSegments;
}

const ReducedPaddingCard = (props: any) => (
    <RoundedCard {...props} bodyStyle={{ padding: "10px" }}>
        {props.children}
    </RoundedCard>
);

const AnimatedReducedPaddingCard = animated(ReducedPaddingCard);

// Function to fill missing rows if required
const fillMissingRowsIfRequired = (
    allRowLabels: string[],
    arr: BaselineMappedRow[]
) => {
    allRowLabels.forEach((label) => {
        if (!arr.find((item) => item.x === label)) {
            arr.push({
                x: label,
                base: 0,
                forecast: 0,
            });
        }
    });
};

const generateMappedAreas = (
    baselineData: BaselineRawRow[],
    filteredSegments: FilteredSegments,
    uniqueFieldLabels: { areas: string[]; classes: string[]; days: string[] }
) => {
    let areas: BaselineMappedRow[] = [];
    baselineData
        .filter(filterBaselineDataIfRequired(filteredSegments, false, true, true))
        .reduce(groupDataByField(areas, "mapped_area"), {});
    areas = areas.filter((row: BaselineMappedRow) => row.x !== "");
    fillMissingRowsIfRequired(uniqueFieldLabels.areas, areas);
    return areas;
};

const generateMappedClasses = (
    baselineData: BaselineRawRow[],
    filteredSegments: FilteredSegments,
    uniqueFieldLabels: { areas: string[]; classes: string[]; days: string[] }
) => {
    let classes: BaselineMappedRow[] = [];
    baselineData
        .filter(filterBaselineDataIfRequired(filteredSegments, true, false, true))
        .reduce(groupDataByField(classes, "mapped_class"), {});
    classes = classes.filter((row: BaselineMappedRow) => row.x !== "");
    fillMissingRowsIfRequired(uniqueFieldLabels.classes, classes);
    return classes;
};

const generateMappedDays = (
    baselineData: BaselineRawRow[],
    filteredSegments: FilteredSegments,
    uniqueFieldLabels: { areas: string[]; classes: string[]; days: string[] }
) => {
    const days: BaselineMappedRow[] = [];
    baselineData
        .map((row: BaselineRawRow) => ({
            ...row,
            day: moment(row.relative_timestamp).format("dddd"),
            shiftDate: getShiftDate(moment(row.relative_timestamp)),
        }))
        .filter(filterBaselineDataIfRequired(filteredSegments, true, true, false))
        .reduce(groupDataByField(days, "shiftDate"), {});
    fillMissingRowsIfRequired(uniqueFieldLabels.days, days);
    return days;
};

const AnimatedRow = animated(Row);

const SegmentTablesGroup = ({
    baselineData,
    loading,
    mode,
    setFilteredSegments,
    filteredSegments,
}: Props): ReactElement => {
    const [mappedAreas, setMappedAreas] = useState<BaselineMappedRow[]>([]);
    const [mappedClasses, setMappedClasses] = useState<BaselineMappedRow[]>([]);
    const [mappedDays, setMappedDays] = useState<BaselineMappedRow[]>([]);
    const [uniqueFieldLabels, setUniqueFieldLabels] = useState({
        areas: [] as string[],
        classes: [] as string[],
        days: DAYS_OF_THE_WEEK,
    });

    useEffect(() => {
        if (baselineData.length > 0 && uniqueFieldLabels.areas.length === 0) {
            setUniqueFieldLabels({
                ...uniqueFieldLabels,
                areas: _.compact(_.uniq(baselineData.map((row) => row.mapped_area))),
                classes: _.compact(
                    _.uniq(baselineData.map((row) => row.mapped_class))
                ),
            });
        }
    }, [baselineData]);

    useEffect(() => {
        if (
            !loading &&
            baselineData.length > 0 &&
            uniqueFieldLabels.areas.length > 0
        ) {
            setMappedAreas(
                generateMappedAreas(
                    baselineData,
                    filteredSegments,
                    uniqueFieldLabels
                )
            );
            setMappedClasses(
                generateMappedClasses(
                    baselineData,
                    filteredSegments,
                    uniqueFieldLabels
                )
            );
            setMappedDays(
                generateMappedDays(baselineData, filteredSegments, uniqueFieldLabels)
            );
        }
    }, [loading, baselineData, filteredSegments, uniqueFieldLabels]);

    const baselineDataExists = baselineData.length > 0;

    const transitions = useTransition(baselineDataExists, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
        delay: 200,
    });

    const styles = useSpring({
        to: { height: baselineDataExists ? 490 : 0 },
        from: { height: 0 },
        leave: { height: 0 },
        delay: 200,
        config: config.stiff,
    });

    return transitions(({ opacity }, item) => (
        <>
            {item && (
                <AnimatedRow
                    gutter={16}
                    style={{
                        opacity: opacity.to({
                            range: [0.0, 1.0],
                            output: [0, 1],
                        }),
                    }}
                >
                    <Col span={8}>
                        <AnimatedReducedPaddingCard style={styles}>
                            <PaddedTableTitle level={4}>Days</PaddedTableTitle>
                            <SegmentTable
                                mappedData={mappedDays}
                                loading={loading}
                                mode={mode}
                                setFilteredSegments={setFilteredSegments}
                                segmentType={"days"}
                            />
                        </AnimatedReducedPaddingCard>
                    </Col>
                    <Col span={8}>
                        <AnimatedReducedPaddingCard style={styles}>
                            <PaddedTableTitle level={4}>Areas</PaddedTableTitle>
                            <SegmentTable
                                mappedData={mappedAreas}
                                loading={loading}
                                mode={mode}
                                setFilteredSegments={setFilteredSegments}
                                segmentType={"areas"}
                            />
                        </AnimatedReducedPaddingCard>
                    </Col>
                    <Col span={8}>
                        <AnimatedReducedPaddingCard style={styles}>
                            <PaddedTableTitle level={4}>Classes</PaddedTableTitle>
                            <SegmentTable
                                mappedData={mappedClasses}
                                loading={loading}
                                mode={mode}
                                setFilteredSegments={setFilteredSegments}
                                segmentType={"classes"}
                            />
                        </AnimatedReducedPaddingCard>
                    </Col>
                </AnimatedRow>
            )}
        </>
    ));
};

export default SegmentTablesGroup;
