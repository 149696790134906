import { useGetMappingRuleById } from "Hooks/mappingConfigurator";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { JsonGroup, Utils as QbUtils } from "react-awesome-query-builder";

function extractValueById(obj: any, id: string): any | null {
    if (!obj || typeof obj !== "object") {
        throw new Error("Invalid object");
    }

    if (obj.type === "rule" && obj.id === id) {
        return obj.properties?.value;
    }

    if (!obj.children1 || typeof obj.children1 !== "object") {
        throw new Error("Invalid children1");
    }

    const firstChild = obj.children1[Object.keys(obj.children1)[0]];

    if (firstChild.type === "rule" && firstChild.id === id) {
        return firstChild.properties?.value;
    }
    return firstChild.children1?.[id]?.properties?.value;
}

function extractValueByIdRecursive(obj: any, id: string): any | null {
    if (!obj || typeof obj !== "object") {
        throw new Error("Invalid object");
    }

    if (obj.id === id) {
        return obj;
    }

    if (obj.children1) {
        for (const key in obj.children1) {
            if (key === id) {
                return obj.children1[key].properties.value;
            }
            const result = extractValueByIdRecursive(obj.children1[key], id);

            if (result !== null) {
                return result;
            }
        }
    }

    if (obj.children) {
        for (const key in obj.children) {
            const result = extractValueByIdRecursive(obj.children[key], id);
            if (result !== null) {
                return result;
            }
        }
    }

    return null;
}

// This hook is used to resolve the issue where the MultiSelectWidget isn't populating with the correct values initially
export function useMultiSelectWidgetWithAPI({ currentSectionId }) {
    const { id } = useParams<{ id: string }>();
    const [initialValue, setValue] = useState<number[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const { data: existingMappingRule, isFetching: fetchingExistingMappingRule } =
        useGetMappingRuleById(id);

    useEffect(() => {
        if (existingMappingRule && existingMappingRule.rules && currentSectionId) {
            let localValue = extractValueById(
                JSON.parse(
                    JSON.stringify(
                        QbUtils.loadTree(
                            existingMappingRule.rules as any as JsonGroup
                        )
                    )
                ),
                currentSectionId
            );

            if (!Array.isArray(localValue)) {
                localValue = extractValueByIdRecursive(
                    JSON.parse(
                        JSON.stringify(
                            QbUtils.loadTree(
                                existingMappingRule.rules as any as JsonGroup
                            )
                        )
                    ),
                    currentSectionId
                );
            }

            if (!localValue) {
                setLoading(false);
                return;
            }

            if (localValue.length === 0) {
                setLoading(false);
                return;
            }

            // TODO Make this safer
            setValue(localValue?.[0]);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [existingMappingRule, currentSectionId]);

    const isLoading = fetchingExistingMappingRule || loading;

    return useMemo(() => ({ initialValue, isLoading }), [initialValue, isLoading]);
}
