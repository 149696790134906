import React from "react";
import { Context, Forecast } from "../State/store";
import { useParams } from "react-router-dom";
import { UpdateForecastAction } from "../State/actions";
import { applyForecastWithMode } from "../Api/backend";
import { ForecastPageParams } from "../Components/ForecastingDashboardDesktop";
import { BaselineRawRow } from "../Components/ForecastingChartsDataManager/BaselineChart";

interface UseCurrentForecast {
    forecast: Forecast;
    setCurrentForecast: (forecast: Forecast) => void;
    getBaselineData: () => Promise<BaselineRawRow[]>;
    getDriversData: () => Promise<BaselineRawRow[]>;
}

const useCurrentForecast = (): UseCurrentForecast => {
    // Initialize the state
    const [state, dispatch] = React.useContext(Context);
    if (!state.groupData) {
        throw new Error("Group Data not found, this should be impossible");
    }
    const forecasts = state.groupData.forecasts;
    const { id } = useParams<ForecastPageParams>();
    const forecast: Forecast = forecasts.find(
        (forecast) => forecast.forecastId === id
    )!;
    const setCurrentForecast = (forecast: Forecast) => {
        const updateForecastAction: UpdateForecastAction = {
            type: "UPDATE_FORECAST",
            payload: {
                forecast,
            },
        };
        dispatch(updateForecastAction);
    };

    const getBaselineData = () => {
        return applyForecastWithMode(forecast.forecastId, "baseline");
    };

    const getDriversData = () => {
        return applyForecastWithMode(forecast.forecastId, "drivers");
    };

    return { forecast, setCurrentForecast, getBaselineData, getDriversData };
};

export default useCurrentForecast;
