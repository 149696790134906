import { ColumnType } from "antd/lib/table";
import React, { CSSProperties, useMemo } from "react";
import { SEGMENT_TYPE } from "Utils/types";
import { WRANGLR_DARK_BLUE } from "Utils/constants";
import { calculatePercentageDifference, stringSort } from "Utils/utils";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import {
    ComparisonFilter,
    useComparisonFilter,
    useSegmentsFilter,
} from "Store/filterStore";
import { SegmentTableRow } from "./SegmentTableRow";
import { useGetSalesAndStaffHours } from "Pages/Dashboard/Components/SegmentTable/Hooks/useGetSalesAndStaffHours";
import { ExtendedMappingRule } from "./Hooks/useMergeMappingRulesAndData";

export interface SegmentTableProps {
    segment: SEGMENT_TYPE;
    omitTitle?: boolean;
}
export interface SegmentTableRowRecord {
    segment: string;
    sales: RowValue;
    staffHours: RowValue;
    id: string;
}

export interface RowValue {
    total: number | string;
    change?: number;
}

const columnClassName = "segment-table-column";

const onHeaderCell = (): { style: CSSProperties } => ({
    style: {
        color: WRANGLR_DARK_BLUE,
        fontWeight: "bold" as const,
    },
});

const getColumns = ({
    comparisonSelected,
    isForecast = false,
}: {
    comparisonSelected: boolean;
    isForecast: boolean;
}): ColumnType<SegmentTableRowRecord>[] => [
    {
        title: `Name`,
        dataIndex: "segment",
        key: "segment",
        className: columnClassName,
        sortDirections: ["descend", "ascend"],
        sorter: {
            compare: (a, b) => stringSort(a.segment, b.segment),
            multiple: 1,
        },
        onHeaderCell,
    },
    {
        title: "Sales",
        dataIndex: "sales",
        key: "sales",
        className: columnClassName,
        sortDirections: ["ascend", "descend"],
        defaultSortOrder: "descend",
        sorter: {
            compare: (a, b) =>
                parseInt(a.sales.total.toString()) -
                parseInt(b.sales.total.toString()),
            multiple: 5,
        },
        render: (rowValues: RowValue) => (
            <SegmentTableRow
                rowValue={rowValues}
                displayChange={comparisonSelected || isForecast}
            />
        ),
        onHeaderCell,
    },
    {
        title: "/StaffHr",
        dataIndex: "staffHours",
        key: "staffHours",
        className: columnClassName,
        sortDirections: ["descend", "ascend"],
        sorter: {
            compare: (a, b) =>
                // It is safe to assume "manHour" is a number in this context.
                parseInt(a.staffHours.total.toString()) -
                parseInt(b.staffHours.total.toString()),
            multiple: 4,
        },
        render: (rowValue: RowValue) => (
            <SegmentTableRow
                rowValue={rowValue}
                displayChange={comparisonSelected && !isForecast}
                roundNumberWhenFormatting
            />
        ),
        onHeaderCell,
    },
];

const getComparisonFigures = (
    salesCurrentPeriod: number,
    manHrCurrentPeriod: number,
    salesPreviousPeriod: number,
    manHrPreviousPeriod: number
) => {
    // Validate input data types
    if (
        typeof salesCurrentPeriod !== "number" ||
        typeof manHrCurrentPeriod !== "number" ||
        typeof salesPreviousPeriod !== "number" ||
        typeof manHrPreviousPeriod !== "number"
    ) {
        // Handle invalid input types, return default values or throw an error
        return {
            salesChange: 0,
            manHourChange: 0,
        };
    }
    let salesChange = 0;
    let manHourChange = 0;

    try {
        salesChange =
            calculatePercentageDifference(salesCurrentPeriod, salesPreviousPeriod) ??
            0;
        manHourChange =
            calculatePercentageDifference(manHrCurrentPeriod, manHrPreviousPeriod) ??
            0;
    } catch (error) {
        console.error(error);
    }

    // Check for NaN values and limit significant digits
    return {
        salesChange: isNaN(salesChange)
            ? 0
            : salesChange === Number.POSITIVE_INFINITY
            ? 100
            : parseFloat(salesChange.toFixed(2)),
        manHourChange: isNaN(manHourChange)
            ? 0
            : manHourChange === Number.POSITIVE_INFINITY
            ? 100
            : parseFloat(manHourChange.toFixed(2)),
    };
};

export const useSegmentTable = ({ segment }: SegmentTableProps) => {
    const {
        selectedAreas,
        selectedClasses,
        selectedVenues,
        updateAreasFilter,
        updateClassesFilter,
        updateVenuesFilter,
    } = useSegmentsFilter();

    const { data: mappingRules } = useListMappingRules();
    const { selectedComparison } = useComparisonFilter();
    const columns = useMemo(
        () =>
            getColumns({
                comparisonSelected: Boolean(selectedComparison),
                isForecast: selectedComparison === ComparisonFilter.Forecast,
            }),
        [selectedComparison]
    );

    const {
        totalSalesAndStaffHoursByMappingRule,
        averageSalesAndStaffHoursByMappingRule,
        augmentedMappingRules,
        totalSalesAndStaffHoursByMappingRuleForComparison,
        forecastSalesByMappingRules,
        isLoading,
        unmappedSegmentTotals,
    } = useGetSalesAndStaffHours();

    const dataSource = useMemo(() => {
        const mappingRulesForSegment = augmentedMappingRules?.filter(
            ({ exclude, segment_type: ruleSegment }: any) =>
                !exclude && ruleSegment.toUpperCase() === segment.toUpperCase()
        );

        if (!mappingRulesForSegment || !totalSalesAndStaffHoursByMappingRule) {
            return [];
        }

        const unmappedValues = unmappedSegmentTotals?.[segment.toLocaleLowerCase()];

        const unmappedMappingRule: ExtendedMappingRule = {
            segment_name:
                segment === "Area"
                    ? "unmappedArea"
                    : segment === "Class"
                    ? "unmappedClass"
                    : "unmappedVenue",
            transactionTotal: unmappedValues?.salesDifference,
            staffHr: unmappedValues?.staffHr,
            segment_type: segment.toLowerCase(),
            uuid: `unmapped-${segment.toLowerCase()}`,
            parsedValues: [],
            id: `unmapped-${segment.toLowerCase()}`,
            client_group: -1,
            created_date: "",
            last_modified_date: "",
            result: [],
            rules: {},
            unmapped_segments: [],
        };

        const valuesToFilter = unmappedValues
            ? [...mappingRulesForSegment, unmappedMappingRule]
            : [...mappingRulesForSegment];

        return valuesToFilter.map(
            ({
                segment_name,
                uuid: key,
                transactionTotal: totalSales,
                staffHr: totalStaffHours,
            }) => {
                const rowName =
                    segment_name === "unmappedArea"
                        ? "Unmapped Areas"
                        : segment_name === "unmappedClass"
                        ? "Unmapped Classes"
                        : segment_name === "unmappedVenue"
                        ? "Unmapped Venues"
                        : segment_name;

                const {
                    totalSales: totalSalesForComparison,
                    totalStaffHours: totalStaffHoursForComparison,
                } =
                    totalSalesAndStaffHoursByMappingRuleForComparison &&
                    totalSalesAndStaffHoursByMappingRuleForComparison[key]
                        ? totalSalesAndStaffHoursByMappingRuleForComparison[key]
                        : {
                              totalSales:
                                  forecastSalesByMappingRules?.[segment_name],
                              totalStaffHours: undefined,
                          };

                const manHr = isFinite(totalSales / totalStaffHours)
                    ? totalSales / totalStaffHours
                    : 0;
                const { salesChange, manHourChange } =
                    totalSalesForComparison != null ||
                    totalStaffHoursForComparison != null
                        ? getComparisonFigures(
                              totalSales,
                              manHr,
                              totalSalesForComparison,
                              totalSalesForComparison /
                                  (totalStaffHoursForComparison ?? 0)
                          )
                        : {
                              salesChange: undefined,
                              manHourChange: undefined,
                          };

                return {
                    key: rowName,
                    segment: rowName,
                    id: key,
                    sales: {
                        total: totalSales,
                        change: salesChange,
                    },
                    staffHours: {
                        total: manHr,
                        change: manHourChange,
                    },
                };
            }
        );
    }, [
        mappingRules,
        totalSalesAndStaffHoursByMappingRule,
        segment,
        averageSalesAndStaffHoursByMappingRule,
        totalSalesAndStaffHoursByMappingRuleForComparison,
        forecastSalesByMappingRules,
        unmappedSegmentTotals,
    ]);

    const segmentToFilterUpdateFn = {
        Area: updateAreasFilter,
        Venue: updateVenuesFilter,
        Class: updateClassesFilter,
    };

    const onRowClick = (record: SegmentTableRowRecord) => () => {
        const { segment: segmentName, id } = record;

        // Don't allow selection of unmapped segments as they are not really mapping rules
        if (segmentName.includes("Unmapped")) {
            return;
        }

        const currentSelected = {
            Area: selectedAreas,
            Venue: selectedVenues,
            Class: selectedClasses,
        }[segment];

        if (!currentSelected || !currentSelected.includes(id)) {
            segmentToFilterUpdateFn[segment]([...(currentSelected ?? []), id]);
        } else {
            segmentToFilterUpdateFn[segment](
                currentSelected.filter((selected) => selected !== id)
            );
        }
    };
    const getRowClassName = (record: SegmentTableRowRecord) => {
        const { id: uncastedId } = record;

        return selectedAreas?.includes(uncastedId) ||
            selectedVenues?.includes(uncastedId) ||
            selectedClasses?.includes(uncastedId)
            ? "selected-row"
            : "";
    };

    return {
        columns,
        dataSource,
        getRowClassName,
        onRowClick,
        isLoading,
    };
};
