import React, { ReactElement, useMemo, useState } from "react";
import { Button, Col, DatePicker, Form, message, Modal, Row, Select } from "antd";
import { RoundedCard, WranglrDarkBlueTitle } from "../HomeDashboardDesktop";
import styled from "styled-components";
import { Context } from "../../State/store";
import moment from "moment";
import locale from "antd/es/date-picker/locale/en_GB";
import { useHistory } from "react-router-dom";
import { addForecast, getForecasts } from "../../Api/backend";
import { ForecastsTable } from "./ForecastsTable";
import { UpdateGroupData } from "../../State/actions";
import { DEFAULT_DATE_FORMAT, parseToMoment } from "Utils/date-utils";
import { usePreviewMode } from "Store/viewSettingsStore";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { RangePickerProps } from "antd/lib/date-picker";

moment.locale("en", {
    week: {
        dow: 1,
    },
});

const FullHeightRow = styled(Row)`
    height: 85vh;
`;

const RoundedButton = styled(Button)`
    border-radius: 15px;
`;

export const CreateNewForecastButton = (): ReactElement => {
    const [modalVisible, setModalVisible] = useState(false);
    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();

    const CreateNewForecastModal = () => {
        const [confirmLoading, setConfirmLoading] = useState(false);
        const [form] = Form.useForm();
        const { previewMode } = usePreviewMode();

        const { isLoading: isListMappingRulesLoading, data: mappingRules } =
            useListMappingRules();

        const filteredMappingRules = useMemo(
            () =>
                mappingRules
                    ?.filter(({ rules }) => Boolean(rules))
                    .map(({ id, ...rest }) => ({
                        key: id,
                        id,
                        ...rest,
                    }))
                    .filter(
                        (currentMappingRule) =>
                            currentMappingRule.segment_type === "venue" &&
                            currentMappingRule.exclude !== true
                    ),
            [mappingRules]
        );

        const onCancel = () => setModalVisible(false);

        const onOk = () => {
            form.validateFields().then(({ venue, weekPeriod }) => {
                const existingForecast = state.groupData!.forecasts.find(
                    ({ configuration }) =>
                        configuration.venue === venue &&
                        configuration.weekPeriod ===
                            weekPeriod.format(DEFAULT_DATE_FORMAT)
                );

                if (existingForecast) {
                    return history.push(
                        `/forecasting/${existingForecast.forecastId}`
                    );
                }
                setConfirmLoading(true);

                addForecast(venue, weekPeriod)
                    .then((forecast) => {
                        if (forecast.message) {
                            throw forecast.message;
                        }
                        getForecasts().then((forecasts) => {
                            const action: UpdateGroupData = {
                                type: "UPDATE_GROUP_DATA",
                                payload: {
                                    groupData: {
                                        forecasts,
                                    },
                                },
                            };
                            dispatch(action);
                            form.resetFields();
                            history.push(`/forecasting/${forecast.forecastId}`);
                        });
                    })
                    .catch((e) => {
                        if (
                            e.includes(
                                "forecast for this configuration already exists"
                            )
                        ) {
                            form.resetFields();
                            setConfirmLoading(false);
                            message
                                .error(
                                    "forecast for this configuration already exists"
                                )
                                .then();
                        }
                    });
            });
        };

        const venues = previewMode
            ? isListMappingRulesLoading
                ? []
                : filteredMappingRules?.map((currentMappingRule) => ({
                      id: currentMappingRule.id,
                      value: currentMappingRule.segment_name,
                  }))
            : state.groupData!.segments.venues.map((venue) => ({
                  id: venue.primary_id,
                  value: venue.primary_id,
              }));

        const disabledDate: RangePickerProps["disabledDate"] = (current) => {
            // Can not select days before today and today
            return (
                current && current < parseToMoment().subtract(1, "day").endOf("day")
            );
        };

        return (
            <Modal
                visible={modalVisible}
                onCancel={onCancel}
                onOk={onOk}
                title={"Forecast Configuration"}
                confirmLoading={confirmLoading}
            >
                <Form form={form}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <WranglrDarkBlueTitle level={5}>
                                Step 1: Select a Venue
                            </WranglrDarkBlueTitle>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={"venue"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a venue",
                                    },
                                ]}
                            >
                                <Select style={{ width: "100%" }} options={venues} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <WranglrDarkBlueTitle level={5}>
                                Step 2: Select a Week Period
                            </WranglrDarkBlueTitle>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={"weekPeriod"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a week period",
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    picker={"week"}
                                    disabledDate={disabledDate}
                                    defaultPickerValue={moment()
                                        .subtract(1, "week")
                                        .startOf("isoWeek")}
                                    format={(date) => {
                                        return date
                                            .startOf("isoWeek")
                                            .format("YYYY-MM-DD");
                                    }}
                                    locale={locale}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };

    return (
        <>
            <CreateNewForecastModal />
            <RoundedButton
                type={"primary"}
                block={true}
                onClick={() => {
                    setModalVisible(true);
                }}
            >
                Create new forecast
            </RoundedButton>
        </>
    );
};

const NoForecastsCard = () => {
    return (
        <RoundedCard style={{ minHeight: 300 }} bodyStyle={{ height: "270px" }}>
            <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
                <Col>
                    <WranglrDarkBlueTitle level={4}>
                        {"You don't have any forecasts..."}
                    </WranglrDarkBlueTitle>
                </Col>
            </Row>
            <Row justify={"end"}>
                <Col span={8}>
                    <CreateNewForecastButton />
                </Col>
            </Row>
        </RoundedCard>
    );
};

export default function LandingPage(): ReactElement {
    const [state] = React.useContext(Context);
    return (
        <FullHeightRow justify={"center"} align={"middle"}>
            <>
                {state.groupData!.forecasts.length === 0 ? (
                    <Col span={20} style={{ maxWidth: "700px" }}>
                        <NoForecastsCard />
                    </Col>
                ) : (
                    <Col span={20} style={{ maxWidth: "850px" }}>
                        <ForecastsTable forecasts={state.groupData!.forecasts} />
                    </Col>
                )}
            </>
        </FullHeightRow>
    );
}
