import { notification } from "antd";
import { SeriesRow } from "Api/Cube/types";
import {
    CubeQueryBuilder,
    DayToNum,
    getGranularityForDateRange,
    getStaffHoursDimension,
} from "Api/Cube/utils";
import { MappingRuleResult } from "Api/mappingConfigurator";
import { chain, cloneDeep, sortBy } from "lodash";
import { getComparisonPeriod } from "Pages/Dashboard/dashboard-utils";
import { useCallback, useContext, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import {
    AggregateFilter,
    ComparisonFilter,
    useAggregateFilter,
    useComparisonFilter,
    useDateRangeFilter,
    useDaysFilter,
    useHoursFilter,
    useSegmentsFilter,
} from "Store/filterStore";
import {
    calculateNumberOfDaysAndWeeksSelectedInRange,
    countNumberOfDaysBetweenDates,
    DateRange,
    DATE_TIME_FORMAT,
    formatForDisplay,
    parseToMoment,
} from "Utils/date-utils";
import { buildCubeFilters } from "Utils/mapping-configurator-utils";
import { DAYS_OF_WEEK } from "Utils/types";
import {
    useListIntegrationSources,
    useListMappingRules,
} from "./mappingConfigurator";
import { CubeContext } from "@cubejs-client/react";
import { MappingRuleV2, fetchAllSegments } from "Api/mappingConfiguratorV2";
import { convertUnmappedSegmentResponseToSegments } from "Pages/MappingConfigurator/MappingConfiguratorEditorPage.logic";
import { convertNumericalIdsToPseudoUniqueIds } from "Pages/Dashboard/Components/SegmentTable/Hooks/useGetSalesAndStaffHoursByDates";

const CUBE_NAME = "actualsUnmappedNoPreAgg";
const FORECAST_CUBE_NAME = "forecastsUnmapped";

interface GetSalesAndStaffHours {
    range: DateRange<string>;
    enabled?: boolean;
}
interface GetSalesAndStaffHoursSeries extends GetSalesAndStaffHours {
    excludeStaffHours?: boolean;
    doNotApplyMovingAverage?: boolean;
}

export const useGetForecastSalesSeries = (params: GetSalesAndStaffHours) => {
    const {
        range: { start, end },
        enabled = true,
    } = params;
    const { cubejsApi } = useContext(CubeContext);

    const { selectedDays } = useDaysFilter();
    const { selectedHours } = useHoursFilter();
    const { mappingRuleById } = useListMappingRules();
    const { selectedAreas, selectedClasses, selectedVenues } = useSegmentsFilter();
    const selectedDaysToNum = useMemo(
        () =>
            selectedDays?.flatMap((day) => {
                const dayOfWeek = Number(DayToNum(day as DAYS_OF_WEEK));
                return [String(dayOfWeek)];
            }),
        [selectedDays]
    );

    const query = enabled
        ? CubeQueryBuilder({
              measures: [`${FORECAST_CUBE_NAME}.forecastTransactionTotal`],
              order: { [`${FORECAST_CUBE_NAME}.localisedPeriod`]: "asc" },
              timeDimensions: [
                  {
                      dimension: `${FORECAST_CUBE_NAME}.localisedPeriod`,
                      dateRange: [start, end],
                      granularity: getGranularityForDateRange({ start, end }),
                  },
              ],
          })
              .addFilters(
                  [
                      {
                          member: `${FORECAST_CUBE_NAME}.mappedVenue`,
                          operator: "equals",
                          values: selectedVenues?.map(
                              (selectedVenueId) =>
                                  mappingRuleById[selectedVenueId].segment_name
                          ),
                      },
                  ],
                  Boolean(selectedVenues && selectedVenues.length > 0)
              )
              .addFilters(
                  [
                      {
                          member: `${FORECAST_CUBE_NAME}.mappedArea`,
                          operator: "equals",
                          values: selectedAreas?.map(
                              (selectedAreaId) =>
                                  mappingRuleById[selectedAreaId].segment_name
                          ),
                      },
                  ],
                  Boolean(selectedAreas && selectedAreas.length > 0)
              )
              .addFilters(
                  [
                      {
                          member: `${FORECAST_CUBE_NAME}.mappedClass`,
                          operator: "equals",
                          values: selectedClasses?.map(
                              (selectedClassId) =>
                                  mappingRuleById[selectedClassId].segment_name
                          ),
                      },
                  ],
                  Boolean(selectedClasses && selectedClasses.length > 0)
              )
              .addFilters(
                  [
                      {
                          member: `${FORECAST_CUBE_NAME}.shiftDay`,
                          operator: "equals",
                          values: selectedDaysToNum,
                      },
                  ],
                  selectedDaysToNum !== undefined &&
                      selectedDaysToNum.length !== 0 &&
                      selectedDaysToNum.length !== 7
              )
              .addFilters(
                  [
                      {
                          member: `${FORECAST_CUBE_NAME}.hour`,
                          operator: "equals",
                          values: selectedHours?.map(String),
                      },
                  ],
                  selectedHours != undefined &&
                      selectedHours.length !== 0 &&
                      selectedHours.length !== 24
              )
              .getResult({ ignoreTimeZone: true })
        : null;

    const { isLoading: fetchingSalesAndStaffHours, data: forecastSaleSeries } =
        useQuery(["FORECAST_SERIES", query], () => cubejsApi.load(query!), {
            enabled: Boolean(query),
            refetchOnWindowFocus: "always",
            refetchOnMount: "always",
            refetchOnReconnect: "always",
            select: (result) => {
                if (result.series({ fillMissingDates: false }).length === 0) {
                    notification.open({
                        message: "Missing Forecast",
                        description: "We don't have forecast for this date range",
                        key: "missing-forecast",
                    });
                }

                return result.series({
                    fillMissingDates: true,
                });
            },
        });

    return {
        isLoading: fetchingSalesAndStaffHours,
        forecastSaleSeries,
    };
};

export const useGetSalesSeries = () => {
    const {
        selectedDateRange: { start, end },
    } = useDateRangeFilter();
    const { selectedComparison } = useComparisonFilter();
    const { selectedAggregate } = useAggregateFilter();

    const forecastComparisonSelected =
        selectedComparison === ComparisonFilter.Forecast;
    const comparisonPeriod = getComparisonPeriod({ start, end, selectedComparison });

    const range = {
        start: parseToMoment(start).add(6, "hours").format(DATE_TIME_FORMAT),
        end: parseToMoment(end)
            .add(1, "day")
            .add(5, "hours")
            .add(45, "minutes")
            .format(DATE_TIME_FORMAT),
    };

    const { saleAndStaffHoursSeries, isLoading: loadingData } =
        useGetSalesAndStaffHoursSeries({
            range,
            enabled: true,
            excludeStaffHours: true,
        });

    const {
        saleAndStaffHoursSeries: saleAndStaffHoursSeriesForComparison,
        isLoading: loadingComparisonData,
    } = useGetSalesAndStaffHoursSeries({
        range: comparisonPeriod ?? range,
        enabled:
            !selectedAggregate &&
            Boolean(comparisonPeriod) &&
            !forecastComparisonSelected,
        excludeStaffHours: true,
    });

    const { averageSaleAndStaffHoursSeries, isLoading: loadingAverageData } =
        useGetAverageSalesAndStaffHoursSeries({
            range: {
                start,
                end,
            },
            enabled: Boolean(selectedAggregate) && !forecastComparisonSelected,
            excludeStaffHours: true,
            doNotApplyMovingAverage: true,
        });

    const { forecastSaleSeries, isLoading: loadingForecastSeries } =
        useGetForecastSalesSeries({
            range,
            enabled: forecastComparisonSelected,
        });

    return {
        isLoading:
            loadingComparisonData ||
            loadingData ||
            loadingAverageData ||
            loadingForecastSeries,
        salesSeries: saleAndStaffHoursSeries?.[0],
        salesSeriesForComparison: saleAndStaffHoursSeriesForComparison?.[0],
        averageSaleSeries: averageSaleAndStaffHoursSeries?.[0],
        forecastSaleSeries: forecastSaleSeries?.[0],
    };
};

export const useGetAverageSalesAndStaffHoursSeries = (
    params: GetSalesAndStaffHoursSeries
) => {
    const {
        range,
        enabled = true,
        excludeStaffHours,
        doNotApplyMovingAverage = false,
    } = params;
    const { cubejsApi } = useContext(CubeContext);
    const { data: segments, isLoading: v2SegmentsLoading } = useQuery(
        "V2_ALL_SEGMENTS",
        fetchAllSegments,
        {
            staleTime: Infinity,
            select: (result) => {
                if (!result) return [];

                return convertUnmappedSegmentResponseToSegments(result);
            },
        }
    );

    const { start, end } = range;
    const { selectedAreas, selectedClasses, selectedVenues } = useSegmentsFilter();
    const { updateDateRangeFilter } = useDateRangeFilter();
    const { selectedDays } = useDaysFilter();
    const { selectedHours } = useHoursFilter();
    const selectedDaysToNum = useMemo(
        () =>
            selectedDays?.flatMap((day) => {
                const dayOfWeek = Number(DayToNum(day as DAYS_OF_WEEK));
                return [String(dayOfWeek)];
            }),
        [selectedDays]
    );
    const lastCompleteCubeJsQueryHour = useRef(new Date().getHours());

    const { isLoading: fetchingMappingRules, data: mappingRules } =
        useListMappingRules();
    const { data: integrationSources, isLoading: fetchingIntegrationSources } =
        useListIntegrationSources();

    const { selectedAggregate } = useAggregateFilter();

    const serviceIdsByIntegrationName = integrationSources
        ? chain(integrationSources)
              .keyBy("integrationName")
              .mapValues("services")
              .value()
        : undefined;

    const allMappingRules = mappingRules?.reduce<{
        excludedMappingRules: MappingRuleV2[];
        mappingRules: MappingRuleV2[];
    }>(
        (result, mappingRule) => {
            if (mappingRule.exclude) {
                result.excludedMappingRules.push(mappingRule);
            } else {
                result.mappingRules.push(mappingRule);
            }
            return result;
        },
        {
            excludedMappingRules: [],
            mappingRules: [],
        }
    );

    const areaFilterSelected = useMemo(
        () => selectedAreas && selectedAreas.length > 0,
        [selectedAreas]
    );
    const venueFilterSelected = useMemo(
        () => selectedVenues && selectedVenues.length > 0,
        [selectedVenues]
    );
    const classFilterSelected = useMemo(
        () => selectedClasses && selectedClasses.length > 0,
        [selectedClasses]
    );

    const mappingResults = useMemo(() => {
        return allMappingRules?.mappingRules
            .filter(({ id, segment_type }) => {
                if (venueFilterSelected && segment_type === "venue") {
                    return selectedVenues?.includes(id);
                } else if (areaFilterSelected && segment_type === "area") {
                    return selectedAreas?.includes(id);
                } else if (classFilterSelected && segment_type === "class") {
                    return selectedClasses?.includes(id);
                }

                return false;
            })
            .flatMap(({ result }) => cloneDeep(result))
            .reduce<MappingRuleResult[]>((result, mappingResult) => {
                const integrationSourceResult = result.find(
                    ({ integrationSource }) =>
                        integrationSource === mappingResult.integrationSource
                );

                if (!integrationSourceResult) {
                    return [...result, mappingResult];
                }

                if (mappingResult.areaIds) {
                    integrationSourceResult.areaIds = [
                        ...(integrationSourceResult.areaIds ?? []),
                        ...mappingResult.areaIds,
                    ];
                }

                if (mappingResult.classIds) {
                    integrationSourceResult.classIds = [
                        ...(integrationSourceResult.classIds ?? []),
                        ...mappingResult.classIds,
                    ];
                }

                if (mappingResult.venueIds) {
                    integrationSourceResult.venueIds = [
                        ...(integrationSourceResult.venueIds ?? []),
                        ...mappingResult.venueIds,
                    ];
                }

                return result;
            }, []);
    }, [
        allMappingRules?.mappingRules,
        areaFilterSelected,
        classFilterSelected,
        selectedAreas,
        selectedClasses,
        selectedVenues,
        venueFilterSelected,
    ]);

    const groupServices = useMemo(
        () =>
            serviceIdsByIntegrationName
                ? Object.values(serviceIdsByIntegrationName).flatMap((value) =>
                      value.map(String)
                  )
                : undefined,
        [serviceIdsByIntegrationName]
    );

    const excludedMappingResults = useMemo(() => {
        if (!segments) return undefined;
        return allMappingRules?.excludedMappingRules.flatMap(({ result }) =>
            convertNumericalIdsToPseudoUniqueIds(result, segments)
        );
    }, [allMappingRules?.excludedMappingRules, segments]);

    const serviceFilterForExclusionRule = useMemo(() => {
        if (
            !excludedMappingResults ||
            !serviceIdsByIntegrationName ||
            !groupServices
        )
            return [];

        const serviceIds = chain(excludedMappingResults)
            .groupBy("integrationSource")
            .flatMap(
                (_, integrationSource) =>
                    serviceIdsByIntegrationName[integrationSource]
            )
            .value();
        if (serviceIds.length === groupServices.length) {
            return [];
        }
        return [
            {
                member: `${CUBE_NAME}.serviceId`,
                values: groupServices.filter(
                    (serviceId) => !serviceIds.includes(Number(serviceId))
                ),
                operator: "equals",
            },
        ];
    }, [excludedMappingResults, groupServices, serviceIdsByIntegrationName]);

    const query =
        enabled &&
        excludedMappingResults &&
        serviceIdsByIntegrationName &&
        groupServices &&
        mappingResults
            ? CubeQueryBuilder({
                  measures: [`${CUBE_NAME}.transactionTotal`],
                  order: { [`${CUBE_NAME}.sortablePeriodMedianDaily`]: "asc" },
                  filters: [
                      {
                          member: `${CUBE_NAME}.shiftDate`,
                          operator: "inDateRange",
                          values: [start, end],
                      },
                  ],
              })
                  .addOrder(
                      { [`${CUBE_NAME}.sortablePeriodMedianDaily`]: "asc" },
                      selectedAggregate === AggregateFilter.AverageDay
                  )
                  .addOrder(
                      { [`${CUBE_NAME}.sortablePeriodMedianWeekly`]: "asc" },
                      selectedAggregate === AggregateFilter.AverageWeek
                  )
                  .addTimeDimensions(
                      [
                          {
                              dimension: `${CUBE_NAME}.sortablePeriodMedianDaily`,
                              granularity: "minute",
                          },
                      ],
                      selectedAggregate === AggregateFilter.AverageDay
                  )
                  .addTimeDimensions(
                      [
                          {
                              dimension: `${CUBE_NAME}.sortablePeriodMedianWeekly`,
                              granularity: "hour",
                          },
                      ],
                      selectedAggregate === AggregateFilter.AverageWeek
                  )
                  .addMeasures(
                      [`${CUBE_NAME}.activeStaff`],
                      !excludeStaffHours &&
                          selectedAggregate === AggregateFilter.AverageDay
                  )
                  .addMeasures(
                      [`${CUBE_NAME}.activeStaffHourly`],
                      !excludeStaffHours &&
                          selectedAggregate === AggregateFilter.AverageWeek
                  )
                  .addFilters(
                      [
                          {
                              member: `${CUBE_NAME}.shiftDay`,
                              operator: "equals",
                              values: selectedDaysToNum,
                          },
                      ],
                      selectedDaysToNum !== undefined &&
                          selectedDaysToNum?.length !== 0
                  )
                  .addFilters(
                      [
                          {
                              member: `${CUBE_NAME}.hour`,
                              operator: "equals",
                              values: selectedHours?.map(String),
                          },
                      ],
                      selectedHours != undefined &&
                          selectedHours.length !== 0 &&
                          selectedHours.length !== 24
                  )
                  .addFilters(
                      [
                          {
                              or: [
                                  //TODO Enable this again
                                  ...serviceFilterForExclusionRule,
                                  ...excludedMappingResults.map((result) => {
                                      return {
                                          and: [
                                              {
                                                  member: `${CUBE_NAME}.serviceId`,
                                                  values: groupServices,
                                                  operator: "equals",
                                              },
                                              ...buildCubeFilters({
                                                  cubeName: CUBE_NAME,
                                                  mappingResults: [result],
                                                  operator: "notEquals",
                                                  serviceIdsByIntegrationName,
                                              }),
                                          ],
                                      };
                                  }),
                              ],
                          },
                      ],
                      excludedMappingResults.length > 0
                  )
                  .addFilters(
                      [
                          {
                              or: buildCubeFilters({
                                  cubeName: CUBE_NAME,
                                  mappingResults: mappingResults,
                                  operator: "equals",
                                  serviceIdsByIntegrationName,
                              }),
                          },
                      ],
                      mappingResults.length > 0
                  )
                  .getResult({ ignoreTimeZone: true })
            : null;

    const { isLoading: fetchingSalesAndStaffHours, data: saleAndStaffHoursSeries } =
        useQuery(["DYNAMIC_DATA_AVERAGE", query], () => cubejsApi.load(query!), {
            enabled: Boolean(query),
            select: (result) => {
                return result.series({ fillMissingDates: false });
            },
            onSettled: () => {
                lastCompleteCubeJsQueryHour.current = onSettledHandler(
                    lastCompleteCubeJsQueryHour.current,
                    updateDateRangeFilter,
                    start,
                    end
                );
            },
        });

    const calculateAverageDayOrWeek = useCallback(
        (series: SeriesRow[]) => {
            const { numberOfWeeksSelected, numberOfDaysSelected } =
                calculateNumberOfDaysAndWeeksSelectedInRange(
                    start,
                    end,
                    selectedDays
                );

            if (selectedAggregate === AggregateFilter.AverageWeek) {
                const averageSeries = sortBy(
                    series.map(({ x, value = 0 }) => {
                        const processedX =
                            x < "1970-01-01T06:00:00.000"
                                ? parseToMoment(x)
                                      .add(7, "days")
                                      .format(DATE_TIME_FORMAT)
                                : x;

                        return {
                            value:
                                numberOfWeeksSelected === 0
                                    ? value
                                    : value / numberOfWeeksSelected,
                            x: parseToMoment(processedX)
                                .set({
                                    // Choose 2018 here as the 1 -> 7 / 01/2018 is Mon -> Sun
                                    year: 2018,
                                })
                                .format(DATE_TIME_FORMAT),
                        };
                    }),
                    "x"
                );

                return doNotApplyMovingAverage
                    ? averageSeries
                    : averageSeries.map(({ value, ...otherProps }, i) => {
                          const valueBefore = averageSeries[i - 1]?.value ?? 0;
                          const valueAfter = averageSeries[i + 1]?.value ?? 0;
                          const window = valueBefore && valueAfter ? 3 : 2;
                          return {
                              ...otherProps,
                              value: (valueBefore + valueAfter + value) / window,
                          };
                      });
            }

            const averageSeries = series
                .map(({ x, value = 0 }) => {
                    const parsedMomentX = parseToMoment(x);
                    const currentYear = parseToMoment().get("year");

                    parsedMomentX.set({ year: currentYear });
                    if (parsedMomentX.get("hours") < 6) {
                        parsedMomentX.add(1, "day");
                    }
                    return {
                        value:
                            numberOfDaysSelected === 0
                                ? value
                                : value / numberOfDaysSelected,
                        x: parsedMomentX.format(DATE_TIME_FORMAT),
                    };
                })
                .sort((a, b) => (a.x > b.x ? 1 : -1));
            return doNotApplyMovingAverage
                ? averageSeries
                : averageSeries.map(({ value, ...otherProps }, i) => {
                      const valueBefore = averageSeries[i - 1]?.value ?? 0;
                      const valueAfter = averageSeries[i + 1]?.value ?? 0;
                      const window = valueBefore && valueAfter ? 3 : 2;
                      return {
                          ...otherProps,
                          value: (valueBefore + valueAfter + value) / window,
                      };
                  });
        },
        [doNotApplyMovingAverage, end, selectedAggregate, selectedDays, start]
    );

    const averageSaleAndStaffHoursSeries = useMemo(() => {
        if (!saleAndStaffHoursSeries) return;

        return saleAndStaffHoursSeries.map(({ series, ...others }) => ({
            ...others,
            series: calculateAverageDayOrWeek(series),
        }));
    }, [calculateAverageDayOrWeek, saleAndStaffHoursSeries]);

    return {
        isLoading:
            fetchingSalesAndStaffHours ||
            fetchingMappingRules ||
            fetchingIntegrationSources,
        averageSaleAndStaffHoursSeries,
    };
};

export const useGetSalesAndStaffHoursSeries = (
    params: GetSalesAndStaffHoursSeries
) => {
    const { range, enabled = true, excludeStaffHours } = params;
    const { cubejsApi } = useContext(CubeContext);

    const { start, end } = range;
    const { selectedAreas, selectedClasses, selectedVenues } = useSegmentsFilter();
    const { updateDateRangeFilter } = useDateRangeFilter();
    const { selectedDays } = useDaysFilter();
    const { selectedHours } = useHoursFilter();

    const { data: segments, isLoading: v2SegmentsLoading } = useQuery(
        "V2_ALL_SEGMENTS",
        fetchAllSegments,
        {
            staleTime: Infinity,
            select: (result) => {
                if (!result) return [];

                return convertUnmappedSegmentResponseToSegments(result);
            },
        }
    );
    const lastCompleteCubeJsQueryHour = useRef(new Date().getHours());

    const { isLoading: fetchingMappingRules, data: mappingRules } =
        useListMappingRules();
    const { data: integrationSources, isLoading: fetchingIntegrationSources } =
        useListIntegrationSources();

    const serviceIdsByIntegrationName = integrationSources
        ? chain(integrationSources)
              .keyBy("integrationName")
              .mapValues("services")
              .value()
        : undefined;

    const allMappingRules = mappingRules?.reduce<{
        excludedMappingRules: MappingRuleV2[];
        mappingRules: MappingRuleV2[];
    }>(
        (result, mappingRule) => {
            if (mappingRule.exclude) {
                result.excludedMappingRules.push(mappingRule);
            } else {
                result.mappingRules.push(mappingRule);
            }
            return result;
        },
        {
            excludedMappingRules: [],
            mappingRules: [],
        }
    );

    const areaFilterSelected = useMemo(
        () => selectedAreas && selectedAreas.length > 0,
        [selectedAreas]
    );
    const venueFilterSelected = useMemo(
        () => selectedVenues && selectedVenues.length > 0,
        [selectedVenues]
    );
    const classFilterSelected = useMemo(
        () => selectedClasses && selectedClasses.length > 0,
        [selectedClasses]
    );

    const mappingResults = useMemo(() => {
        return allMappingRules?.mappingRules
            .filter(({ segment_type, id }) => {
                if (venueFilterSelected && segment_type === "venue") {
                    return selectedVenues?.includes(id);
                } else if (areaFilterSelected && segment_type === "area") {
                    return selectedAreas?.includes(id);
                } else if (classFilterSelected && segment_type === "class") {
                    return selectedClasses?.includes(id);
                }

                return false;
            })
            .flatMap(({ result }) => cloneDeep(result))
            .reduce<MappingRuleResult[]>((result, mappingResult) => {
                const integrationSourceResult = result.find(
                    ({ integrationSource }) =>
                        integrationSource === mappingResult.integrationSource
                );

                if (!integrationSourceResult) {
                    return [...result, mappingResult];
                }

                if (mappingResult.areaIds) {
                    integrationSourceResult.areaIds = [
                        ...(integrationSourceResult.areaIds ?? []),
                        ...mappingResult.areaIds,
                    ];
                }

                if (mappingResult.classIds) {
                    integrationSourceResult.classIds = [
                        ...(integrationSourceResult.classIds ?? []),
                        ...mappingResult.classIds,
                    ];
                }

                if (mappingResult.venueIds) {
                    integrationSourceResult.venueIds = [
                        ...(integrationSourceResult.venueIds ?? []),
                        ...mappingResult.venueIds,
                    ];
                }

                return result;
            }, []);
    }, [
        allMappingRules?.mappingRules,
        areaFilterSelected,
        classFilterSelected,
        selectedAreas,
        selectedClasses,
        selectedVenues,
        venueFilterSelected,
    ]);

    const selectedDaysToNum = useMemo(
        () =>
            selectedDays?.flatMap((day) => {
                const dayOfWeek = Number(DayToNum(day as DAYS_OF_WEEK));
                return [String(dayOfWeek)];
            }),
        [selectedDays]
    );

    const daysDifference = useMemo(
        () => countNumberOfDaysBetweenDates(start, end),
        [start, end]
    );

    const groupServices = useMemo(
        () =>
            serviceIdsByIntegrationName
                ? Object.values(serviceIdsByIntegrationName).flatMap((value) =>
                      value.map(String)
                  )
                : undefined,
        [serviceIdsByIntegrationName]
    );

    const excludedMappingResults = useMemo(() => {
        if (!segments) return undefined;
        return allMappingRules?.excludedMappingRules.flatMap(({ result }) =>
            convertNumericalIdsToPseudoUniqueIds(result, segments)
        );
    }, [allMappingRules?.excludedMappingRules]);

    const serviceFilterForExclusionRule = useMemo(() => {
        if (
            !excludedMappingResults ||
            !serviceIdsByIntegrationName ||
            !groupServices
        )
            return [];

        const serviceIds = chain(excludedMappingResults)
            .groupBy("integrationSource")
            .flatMap(
                (_, integrationSource) =>
                    serviceIdsByIntegrationName[integrationSource]
            )
            .value();

        if (serviceIds.length === groupServices.length) {
            return [];
        }

        return [
            {
                member: `${CUBE_NAME}.serviceId`,
                values: groupServices.filter(
                    (serviceId) => !serviceIds.includes(Number(serviceId))
                ),
                operator: "equals",
            },
        ];
    }, [excludedMappingResults, groupServices, serviceIdsByIntegrationName]);

    const query =
        enabled &&
        excludedMappingResults &&
        serviceIdsByIntegrationName &&
        groupServices &&
        mappingResults
            ? CubeQueryBuilder({
                  measures: [`${CUBE_NAME}.transactionTotal`],
                  order:
                      daysDifference <= 7
                          ? { [`${CUBE_NAME}.period`]: "asc" }
                          : { [`${CUBE_NAME}.shiftDate`]: "asc" },
                  timeDimensions: [
                      {
                          dimension:
                              daysDifference <= 7
                                  ? `${CUBE_NAME}.period`
                                  : `${CUBE_NAME}.shiftDate`,
                          dateRange: [start, end],
                          granularity: getGranularityForDateRange({ start, end }),
                      },
                  ],
              })
                  .addMeasures(
                      [getStaffHoursDimension({ range, cubeName: CUBE_NAME })],
                      !excludeStaffHours
                  )
                  .addFilters(
                      [
                          {
                              or: buildCubeFilters({
                                  cubeName: CUBE_NAME,
                                  mappingResults: mappingResults,
                                  operator: "equals",
                                  serviceIdsByIntegrationName,
                              }),
                          },
                      ],
                      mappingResults.length > 0
                  )
                  .addFilters(
                      [
                          {
                              or: [
                                  ...serviceFilterForExclusionRule,
                                  ...excludedMappingResults.map((result) => {
                                      return {
                                          and: [
                                              {
                                                  member: `${CUBE_NAME}.serviceId`,
                                                  values: groupServices,
                                                  operator: "equals",
                                              },
                                              ...buildCubeFilters({
                                                  cubeName: CUBE_NAME,
                                                  mappingResults: [result],
                                                  operator: "notEquals",
                                                  serviceIdsByIntegrationName,
                                              }),
                                          ],
                                      };
                                  }),
                              ],
                          },
                      ],
                      excludedMappingResults.length > 0
                  )
                  .addFilters(
                      [
                          {
                              member: `${CUBE_NAME}.shiftDay`,
                              operator: "equals",
                              values: selectedDaysToNum,
                          },
                      ],
                      selectedDaysToNum !== undefined &&
                          selectedDaysToNum?.length !== 0
                  )
                  .addFilters(
                      [
                          {
                              member: `${CUBE_NAME}.hour`,
                              operator: "equals",
                              values: selectedHours?.map(String),
                          },
                      ],
                      selectedHours != undefined &&
                          selectedHours.length !== 0 &&
                          selectedHours.length !== 24
                  )
                  .getResult()
            : null;

    const { isLoading: fetchingSalesAndStaffHours, data: saleAndStaffHoursSeries } =
        useQuery(["DYNAMIC_DATA", query], () => cubejsApi.load(query!), {
            enabled: Boolean(query),
            select: (result) => {
                const fillMissingDates =
                    (selectedDays != undefined &&
                        selectedDays.length !== 0 &&
                        selectedDays.length !== 7 &&
                        daysDifference > 7) ||
                    (selectedHours != undefined &&
                        selectedHours.length !== 0 &&
                        selectedHours.length !== 24);
                return result.series({ fillMissingDates });
            },
            onSettled: () => {
                lastCompleteCubeJsQueryHour.current = onSettledHandler(
                    lastCompleteCubeJsQueryHour.current,
                    updateDateRangeFilter,
                    start,
                    end
                );
            },
        });

    return {
        isLoading:
            fetchingSalesAndStaffHours ||
            fetchingMappingRules ||
            fetchingIntegrationSources,
        saleAndStaffHoursSeries,
    };
};

function onSettledHandler(
    lastCompleteCubeJsQueryHour: number,
    updateDateRangeFilter: (selectedDateRange: DateRange<string>) => void,
    start: string,
    end: string
) {
    const currentTimeHour = new Date().getHours();

    if (lastCompleteCubeJsQueryHour === 5 && currentTimeHour === 6) {
        updateDateRangeFilter({
            start: formatForDisplay(start, "DD-MM-YYYY"),
            end: formatForDisplay(end, "DD-MM-YYYY"),
        });
    }

    return currentTimeHour;
}
