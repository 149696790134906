import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
} from "react";
import CheckboxWithDrawers from "./index";
import { drawerType } from "../SideNavigation/CheckboxManager";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { useVenuesFilter } from "Store/filterStore";
import { chain } from "lodash";

interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}

export default function VenuesCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const { data: mappingRule } = useListMappingRules();
    const { selectedVenues: selectedVenueIds, updateVenuesFilter } =
        useVenuesFilter();

    const venueNameById = chain(mappingRule)
        .keyBy("uuid")
        .mapValues("segment_name")
        .value();

    const venueIdByName = chain(mappingRule)
        .keyBy("segment_name")
        .mapValues("uuid")
        .value();

    const venues =
        mappingRule
            ?.filter(
                ({ segment_type, exclude }) =>
                    segment_type === "venue" && exclude !== true
            )
            .map(({ segment_name }) => segment_name) ?? [];

    const selectedVenues =
        selectedVenueIds?.map((venueId) => venueNameById[venueId] ?? venueId) ?? [];

    const dispatchAction = useCallback(
        (selectedVenues: string[]) => {
            if (mappingRule) {
                updateVenuesFilter(
                    selectedVenues.map((venueName) => venueIdByName[venueName])
                );
            }
        },
        [mappingRule, updateVenuesFilter]
    );

    useEffect(() => {
        dispatchAction([]);
    }, []);

    return (
        <>
            <CheckboxWithDrawers
                options={venues.sort()}
                selectedOptions={selectedVenues}
                dispatchAction={dispatchAction}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Venues"}
                title={"Venues"}
            />
        </>
    );
}
