import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
} from "react";
import CheckboxWithDrawers from "./index";
import { drawerType } from "../SideNavigation/CheckboxManager";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { useAreasFilter } from "Store/filterStore";
import { chain } from "lodash";

interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}

export default function AreasCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const { data: mappingRule } = useListMappingRules();
    const { selectedAreas: selectedAreaIds, updateAreasFilter } = useAreasFilter();

    const areaNameById = chain(mappingRule)
        .keyBy("uuid")
        .mapValues("segment_name")
        .value();

    const areaIdByName = chain(mappingRule)
        .keyBy("segment_name")
        .mapValues("uuid")
        .value();

    const areas =
        mappingRule
            ?.filter(
                ({ segment_type, exclude }) =>
                    segment_type === "area" && exclude !== true
            )
            .map(({ segment_name }) => segment_name) ?? [];

    const selectedAreas =
        selectedAreaIds?.map((areaId) => areaNameById[areaId] ?? areaId) ?? [];

    const dispatchAction = useCallback(
        (selectedAreas: string[]) => {
            if (mappingRule) {
                updateAreasFilter(
                    selectedAreas.map((areaName) => areaIdByName[areaName])
                );
            }
        },
        [mappingRule, updateAreasFilter]
    );

    useEffect(() => {
        dispatchAction([]);
    }, []);

    return (
        <>
            <CheckboxWithDrawers
                options={areas.sort()}
                selectedOptions={selectedAreas}
                dispatchAction={dispatchAction}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Areas"}
                title={"Areas"}
            />
        </>
    );
}
