import React from "react";
import { Modal, Table } from "antd";
import { RemoteSegment } from "Api/mappingConfigurator";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import { fetchUnmappedSegments } from "Api/mappingConfiguratorV2";

type UnmappedItem = RemoteSegment;

type UnmappedItemsTableProps = {
    visible: boolean;
    onOk: () => void;
    onCancel: () => void;
};

const columns = [
    {
        title: "Segment ID",
        dataIndex: "segment_id",
    },
    {
        title: "Segment Type",
        dataIndex: "segment_type",
    },
    {
        title: "Segment Value",
        dataIndex: "segment_value",
    },
    {
        title: "Integration Name",
        dataIndex: "integration_name",
    },
    {
        title: "Parent Segment ID",
        dataIndex: "parent_segment_id",
    },
    {
        title: "Service ID",
        dataIndex: "service_id",
    },
];

export const UnmappedItemsTable: React.FC<UnmappedItemsTableProps> = ({
    visible,
    onOk,
    onCancel,
}) => {
    const { data: dataSource, isLoading } = useQuery(
        "unmappedMappingRules",
        fetchUnmappedSegments,
        {}
    );

    return (
        <Modal
            title="Unmapped Items"
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            width={"100%"}
            footer={
                <CSVLink data={dataSource ?? []} filename="unmapped_items.csv">
                    Download CSV
                </CSVLink>
            }
        >
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                loading={isLoading}
            />
        </Modal>
    );
};
