import React, { useCallback, useContext, useState } from "react";
import ViewSelect from "Components/ViewSelect";
import List from "antd-mobile/es/components/list";
import { ListItem } from "antd-mobile/es/components/list/list-item";
import { presetRanges } from "Components/DateFilter";
import { Context, Filters, State } from "State/store";
import { DateRange, formatForDisplay, parseToMoment } from "Utils/date-utils";
import { Action, LogOutAction, UpdateFiltersAction } from "State/actions";
import { Nullable } from "types";
import { Tag, Toast } from "antd-mobile";
import styled from "styled-components";
import { Modal, Row } from "antd";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import {
    useComparisonFilter,
    useDateRangeFilter,
    useResetFilter,
} from "Store/filterStore";
import { usePreviewMode } from "Store/viewSettingsStore";
import { clearCubeJsToken } from "Api/backend";
import { queryClient } from "index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { QUANTACO_DARK_BLUE } from "Utils/constants";

const { confirm } = Modal;

const DatePickerList = styled.ul`
    margin-bottom: 0;
    padding: 4px 12px;
    line-height: 34px;
    text-align: left;
    list-style: none;
    background-color: #ffffff;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #eee;
`;

const DatePickerListItem = styled.li`
    display: inline-block;
    padding-right: 8px;
`;

const DatePickerTag = styled(Tag)`
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    cursor: pointer;
    font-size: 16px;
`;

const StyledMobileSettingsPageContainer = styled.div`
    /* 65px == Top banner height */
    /* 55px == Bottom banner height */
    min-height: calc(100vh - 65px - 55px);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
`;

const StyledMobileContent = styled.div`
    padding-top: 20px;
    width: 100%;
`;

const StyledListItem = styled(ListItem)`
    .adm-list-item-content-extra {
        user-select: none !important;
    }
    .adm-list-item-content-main {
        display: flex;
        justify-content: space-evenly !important;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center !important;
    }
`;

const DateRangePickerMobile = () => {
    const [state, dispatch] = useContext(Context);
    const {
        filters: { selectedDates, selectedComparison, mode },
    } = state;
    const { updateDateRangeFilter, selectedDateRange } = useDateRangeFilter();
    const { updateComparisonFilter } = useComparisonFilter();
    const { previewMode } = usePreviewMode();
    const [currentDatePickerFocus, setCurrentDatePickerFocus] =
        useState<FocusedInputShape | null>(null);

    const onSubmit = (dates: Nullable<[Date, Date]>) => {
        const today = formatForDisplay(undefined, "YYYY-MM-DD");

        const fromDate = dates
            ? formatForDisplay(dates[0].toString(), "YYYY-MM-DD")
            : today;
        const toDate = dates
            ? formatForDisplay(dates[1].toString(), "YYYY-MM-DD")
            : today;

        const moreThanOneDayWasSelected =
            parseToMoment(toDate).diff(parseToMoment(fromDate), "minutes") >=
            24 * 60;

        const action: UpdateFiltersAction = {
            type: "UPDATE_FILTERS",
            payload: {
                filters: {
                    ...state.filters,
                    selectedDates: {
                        fromDate,
                        toDate,
                        isPresetDateRange: false,
                    },
                    selectedComparison: moreThanOneDayWasSelected
                        ? null
                        : selectedComparison,
                    mode:
                        selectedComparison !== null && mode === "forecast"
                            ? mode
                            : "actual",
                } as Filters,
            },
        };
        dispatch(action);
        updateDateRangeFilter({ start: fromDate, end: toDate });
        // if (moreThanOneDayWasSelected) {
        //     updateComparisonFilter();
        // }
    };
    const isDayInRange = (current) => {
        return (
            current &&
            (current > parseToMoment().endOf("day") ||
                current < parseToMoment("2018-01-01"))
        );
    };

    const startDate = previewMode ? selectedDateRange.start : selectedDates.fromDate;
    const endDate = previewMode ? selectedDateRange.end : selectedDates.toDate;

    return (
        <List style={{ width: "100%" }}>
            <StyledListItem>
                <div>{"Date range:"}</div>
                <DateRangePicker
                    startDate={parseToMoment(startDate)}
                    startDateId="startDate"
                    endDate={parseToMoment(endDate)}
                    endDateId="endDate"
                    displayFormat={() => "DD/MM/YYYY"}
                    onDatesChange={({ startDate, endDate }) => {
                        startDate === null || endDate === null
                            ? onSubmit(null)
                            : onSubmit([startDate.toDate(), endDate.toDate()]);
                    }}
                    focusedInput={currentDatePickerFocus}
                    onFocusChange={(focusedInput) => {
                        blur();
                        setCurrentDatePickerFocus(focusedInput);
                    }}
                    numberOfMonths={1}
                    withPortal
                    hideKeyboardShortcutsPanel
                    isOutsideRange={isDayInRange}
                    minimumNights={0}
                    readOnly
                />
            </StyledListItem>
        </List>
    );
};

const onClickPresetRangeChange = (
    e: any,
    state: State,
    dispatch: React.Dispatch<Action>,
    updateSelectedDateRange: (selectedDateRange: DateRange<string>) => void,
    resetSelectedComparison: () => void
) => {
    const target = e.target as HTMLElement;
    const rangeLabel = target.innerText;
    const selectedRange = presetRanges.find((range) => range.label === rangeLabel)!;
    const [fromDateObj, toDateObj] = selectedRange.range();
    const fromDate = fromDateObj.format("YYYY-MM-DD");
    const toDate = toDateObj.format("YYYY-MM-DD");
    const { selectedComparison, mode } = state.filters;

    const moreThanOneDayWasSelected =
        toDateObj.diff(fromDateObj, "minutes") > 24 * 60;

    const action: UpdateFiltersAction = {
        type: "UPDATE_FILTERS",
        payload: {
            filters: {
                ...state.filters,
                selectedDates: {
                    fromDate,
                    toDate,
                    isPresetDateRange: true,
                    presetDateRangeLabel: selectedRange.label,
                },
                selectedComparison: moreThanOneDayWasSelected
                    ? null
                    : selectedComparison,
                mode:
                    selectedComparison !== null && mode === "forecast"
                        ? mode
                        : "actual",
            } as Filters,
        },
    };
    updateSelectedDateRange({ start: fromDate, end: toDate });
    // if (moreThanOneDayWasSelected) {
    //     resetSelectedComparison();
    // }
    dispatch(action);
    Toast.show({
        content: "Date range updated",
        position: "top",
    });
};

// Create a hook that returns a callback that clears all the local storage and filters and logs the user out and redirects to the login page
export const useLogout = () => {
    const { updateDateRangeFilter } = useDateRangeFilter();
    const { updateComparisonFilter } = useComparisonFilter();
    const resetFilter = useResetFilter();
    const logout = useCallback(() => {
        resetFilter();
        updateDateRangeFilter({ start: "", end: "" });
        updateComparisonFilter();
        clearCubeJsToken();
        localStorage.clear();
        // TODO Query client stuff??
        window.location.href = "/login";
    }, [resetFilter, updateDateRangeFilter, updateComparisonFilter]);
    return logout;
};

export const MobileSettingsPage = () => {
    const [state, dispatch] = useContext(Context);
    const { updateDateRangeFilter } = useDateRangeFilter();
    const { updateComparisonFilter } = useComparisonFilter();
    const onClickPresetRange = useCallback(
        (e: any) => {
            onClickPresetRangeChange(e, state, dispatch, updateDateRangeFilter, () =>
                updateComparisonFilter()
            );
        },
        [updateComparisonFilter, state, dispatch, updateDateRangeFilter]
    );
    const resetFilter = useResetFilter();

    const showConfirm = () => {
        confirm({
            title: "Are you sure you want to logout?",
            icon: <ExclamationCircleOutlined color={QUANTACO_DARK_BLUE} />,
            okText: "Yes",
            cancelText: "No",
            onOk() {
                logout();
            },
        });
    };

    const logout = async () => {
        // Clears any token in storage that are related to the current user's session.
        clearCubeJsToken();
        localStorage.clear();
        const action: LogOutAction = {
            type: "LOG_OUT_CLEAR_STATE",
        };
        dispatch(action);
        queryClient.removeQueries();
        queryClient.clear();
        resetFilter();
    };

    return (
        <StyledMobileSettingsPageContainer>
            <Row style={{ marginBottom: "20px" }}>
                <ViewSelect mode={"mobile"} />
                <StyledMobileContent>
                    <DateRangePickerMobile />

                    <DatePickerList>
                        {presetRanges.map((range) => (
                            <DatePickerListItem
                                key={range.label}
                                onClick={onClickPresetRange}
                            >
                                <DatePickerTag>{range.label}</DatePickerTag>
                            </DatePickerListItem>
                        ))}
                    </DatePickerList>
                </StyledMobileContent>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "40px 0px",
                        width: "100%",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#ffffff",
                            border: "solid 1px #eee",
                            borderRadius: 4,
                            padding: "12px 24px",
                        }}
                        onClick={showConfirm}
                    >
                        <a style={{ color: "#FF2E2E" }}>Log Out</a>
                    </div>
                </div>
            </Row>
        </StyledMobileSettingsPageContainer>
    );
};
