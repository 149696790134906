import { MappingRuleResult, MappingSegment } from "Api/mappingConfigurator";
import { MappingRuleV2 } from "Api/mappingConfiguratorV2";
import cloneDeep from "lodash/cloneDeep";
import { JsonGroup } from "react-awesome-query-builder";

interface FilteredMappingRule {
    rules: JsonGroup;
    segment: MappingSegment;
    segmentName: string;
    priority?: number | undefined;
    result: MappingRuleResult[];
    exclude?: boolean | undefined;
    key: string;
    id: string;
}

function isFilteredMappingRule(obj: any): obj is FilteredMappingRule {
    return (
        obj &&
        typeof obj.rules !== "undefined" &&
        typeof obj.segment !== "undefined" &&
        typeof obj.segmentName === "string" &&
        (typeof obj.priority === "number" || typeof obj.priority === "undefined") &&
        Array.isArray(obj.result) &&
        (typeof obj.exclude === "boolean" || typeof obj.exclude === "undefined") &&
        typeof obj.key === "string" &&
        typeof obj.id === "string"
    );
}

export function getFilteredMappingRules(
    obj: MappingRuleV2[]
): FilteredMappingRule[] {
    if (!Array.isArray(obj)) {
        throw new Error("Object is not an array.");
    }

    const filteredMappingRules: FilteredMappingRule[] = [];

    for (const item of obj) {
        if (isFilteredMappingRule(item)) {
            filteredMappingRules.push(item);
        } else {
            throw new Error(
                "Object does not meet the FilteredMappingRule requirements."
            );
        }
    }

    return filteredMappingRules;
}

export const parseMappingRules = (
    filteredMappingRules: MappingRuleV2[],
    id: string
): {
    area: {};
    class: {
        class: {};
        area: {};
    };
    venue: {};
} => {
    const matchingRuleSetIndex = filteredMappingRules.findIndex(
        (currentMappingRule) => currentMappingRule.id === id
    );
    const mappingRulesDeepClone = cloneDeep(filteredMappingRules);

    if (matchingRuleSetIndex !== -1)
        mappingRulesDeepClone.splice(matchingRuleSetIndex, 1);

    const integrationSourceMap = {
        area: {},
        class: { class: {}, area: {} },
        venue: {},
    };

    const updateMappingObject = (mappingObject, integrationSource, tag, itemId) => {
        const key = `${integrationSource}_${tag}`;

        if (mappingObject[key]) {
            mappingObject[key].push(itemId);
        } else {
            mappingObject[key] = [itemId];
        }
    };

    const updateIntegrationSourceMap = (
        segment: string,
        integrationSource: string,
        mappingObject: any
    ) => {
        if (integrationSourceMap[segment][integrationSource]) {
            integrationSourceMap[segment][integrationSource].push(
                ...mappingObject[integrationSource]
            );
        } else {
            integrationSourceMap[segment][integrationSource] = [
                ...mappingObject[integrationSource],
            ];
        }
    };

    const processMappingResult = (
        segment: string,
        currentParsedMapping: MappingRuleResult,
        tag: string
    ) => {
        const mappingObject = {};

        currentParsedMapping[`${tag}Ids`]?.forEach((itemId) => {
            updateMappingObject(
                mappingObject,
                currentParsedMapping.integrationSource,
                tag,
                itemId
            );
        });

        if (Object.keys(mappingObject).length > 0) {
            Object.keys(mappingObject).forEach((currentIntegrationSource) => {
                updateIntegrationSourceMap(
                    segment,
                    currentIntegrationSource,
                    mappingObject
                );
            });
        }
    };

    mappingRulesDeepClone.forEach((currentRule) => {
        const { segment_type, result } = currentRule;

        if (!["venue", "area", "class"].includes(segment_type)) {
            console.error("Unknown segment type!!!");
            return;
        }

        if (segment_type === "class") {
            result.forEach((currentParsedMapping) => {
                processMappingResult("class", currentParsedMapping, "area");
                processMappingResult("class", currentParsedMapping, "class");
            });
        } else {
            result.forEach((currentParsedMapping) => {
                processMappingResult(
                    segment_type,
                    currentParsedMapping,
                    segment_type
                );
            });
        }
    });

    return integrationSourceMap;
};
