import React, { useContext } from "react";
import { HomeDashboardDesktop } from "../Components/HomeDashboardDesktop";
import BaseDesktopPage from "./BaseDesktopPage";
import { Context } from "State/store";
import { useIsMobile } from "Components/RedirectMobileWrapper/RedirectMobileWrapper";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { MobileHomeScreen } from "Components/Mobile/MobileHomeScreen/MobileHomeScreen";
import { useGtag } from "Utils/firebase/GTag";
import { useReturnWidget } from "Utils/widget";
import { GroupData } from "Api/backend";

const getUserName = (groupData: GroupData | undefined) => {
    if (!groupData?.user?.first_name) return `Unknown Name`;
    if (!groupData?.user?.last_name) return `${groupData?.user?.first_name}`;
    return `${groupData?.user?.first_name} - ${groupData?.user?.last_name}`;
};

const HomePage = () => {
    useReturnWidget();
    document.title = "Salesline - Home";
    const { isMobile } = useIsMobile();
    const [{ groupData }] = useContext(Context);

    useGtag({
        groupId: groupData?.group.groupId,
        groupName: groupData?.group.groupName,
        userId: groupData?.user.user_id,
        userName: getUserName(groupData),
    });

    return (
        <>
            {isMobile === undefined ? (
                <QuantacoLoader size={135} />
            ) : isMobile === true ? (
                <MobileHomeScreen />
            ) : (
                <BaseDesktopPage page={"home"}>
                    <HomeDashboardDesktop />
                </BaseDesktopPage>
            )}
        </>
    );
};

export default HomePage;
