import { BACKEND_API_URL } from "Utils/constants";
import { getAuthTokens } from "./auth0";
import { JsonGroup } from "react-awesome-query-builder";

export type MappingSegment = "area" | "class" | "venue";

export interface RemoteMappingRule {
    uuid: string;
    rules: {
        rules: JsonGroup;
        segment: MappingSegment;
        segmentName: string;
        priority?: number;
        result: MappingRuleResult[];
        exclude?: boolean;
    };
}

export interface RemoteIntegrationSource {
    integration_id: number;
    integration_name: string;
    services: number[];
}

export interface RemoteSegment {
    segment_id: number;
    segment_type: MappingSegment;
    segment_value: string;
    integration_name: string;
    parent_segment_id?: number;
    service_id: number;
}

export type MappingRuleResult = {
    areaIds?: string[];
    integrationSource: string;
    classIds?: string[];
    venueIds?: string[];
};

type VenueWithAreas = {
    venueId: string;
    areaIds?: string[];
};

export type ExtendedMappingRuleResult = {
    areaIds: string[];
    integrationSource: string;
    classIds: string[];
    venueIds: VenueWithAreas[];
};

export interface MappingRule {
    id: string;
    rules: JsonGroup;
    segment: MappingSegment;
    segmentName: string;
    priority?: number;
    result: MappingRuleResult[];
    exclude?: boolean;
}

export interface IntegrationSource {
    integrationId: number;
    integrationName: string;
    services: number[];
}

// Segment: An interface representing a segment with the following properties:
// 1. segmentId: a number representing the segment's ID.
// 2. segmentType: a MappingSegment type value.
// 3. segmentName: a string representing the segment's name.
// 4. integrationName: a string representing the integration source's name.
// 5. parentSegmentId: an optional number representing the parent segment's ID.
export interface Segment {
    segmentId: number;
    segmentType: MappingSegment;
    segmentName: string;
    integrationName: string;
    parentSegmentId?: number;
    serviceId: number;
    id: number;
}
export interface SegmentWithIntegration {
    segmentId: number;
    segmentType: MappingSegment;
    segmentName: string;
    integrationName: string;
    serviceId: number;
    parentSegmentId?: number;
}

const transformMappingRuleForClient = ({
    uuid: id,
    rules,
}: RemoteMappingRule): MappingRule => ({
    id,
    ...rules,
});

const transformMappingRuleForServer = ({
    id: uuid,
    ...others
}: MappingRule): RemoteMappingRule => {
    return {
        uuid,
        rules: {
            ...others,
        },
    };
};

export const listMappingRules = async (): Promise<MappingRule[]> => {
    const data = await getAuthTokens();
    const accessToken = data?.access_token;
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `bearer ${accessToken}`);

    myHeaders.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const url = `${BACKEND_API_URL}/api/v1/mapping-rules`;

    const result = await fetch(url, requestOptions).then(async (response) => {
        const data = await response.json();
        if (response.ok) {
            return data.map(transformMappingRuleForClient);
        } else {
            return Promise.reject({ ...data, statusCode: response.status });
        }
    });

    return result;
};

export const listIntegrationSources = async (): Promise<
    RemoteIntegrationSource[]
> => {
    const data = await getAuthTokens();
    const accessToken = data?.access_token;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${accessToken}`);

    myHeaders.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const url = `${BACKEND_API_URL}/api/v1/integrations`;
    return fetch(url, requestOptions).then((r) => r.json());
};

export const listSegmentsByIntegrationSource = async (
    integrationSource?: string
): Promise<RemoteSegment[]> => {
    const data = await getAuthTokens();
    const accessToken = data?.access_token;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${accessToken}`);

    myHeaders.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const url = `${BACKEND_API_URL}/api/v1/unmapped-segments?integration_name=${integrationSource}`;
    return fetch(url, requestOptions).then((r) => r.json());
};

export const createMappingRule = async (mappingRule: MappingRule): Promise<void> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${access_token}`);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify(transformMappingRuleForServer(mappingRule)),
    };
    const url = `${BACKEND_API_URL}/api/v1/mapping-rules`;
    const r = await fetch(url, requestOptions);
    return await r.json();
};

export const updateMappingRule = async (mappingRule: MappingRule): Promise<void> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${access_token}`);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "PUT",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify(transformMappingRuleForServer(mappingRule)),
    };
    const url = `${BACKEND_API_URL}/api/v1/mapping-rules/${mappingRule.id}`;
    const r = await fetch(url, requestOptions);
    return await r.json();
};

// export const getUnmappedMappingRules = async (): Promise<RemoteSegment[]> => {
//     const data = await getAuthTokens();
//     const accessToken = data?.access_token;
//     const myHeaders = new Headers();
//     myHeaders.append("Authorization", `bearer ${accessToken}`);
//     myHeaders.append("Content-Type", "application/json");
//     const requestOptions: RequestInit = {
//         method: "GET",
//         headers: myHeaders,
//         redirect: "follow",
//     };
//     const url = `${BACKEND_API_URL}/api/v1/mapping-rules/unmapped-segments`;
//     const r = await fetch(url, requestOptions);
//     return await r.json();
// };

export const deleteMappingRule = async (mappingRuleID: string): Promise<void> => {
    const data = await getAuthTokens();
    const access_token = data?.access_token;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${access_token}`);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions: RequestInit = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
    };
    const url = `${BACKEND_API_URL}/api/v1/mapping-rules/${mappingRuleID}`;
    const r = await fetch(url, requestOptions);
    return await r.json();
};

export const getMappingRuleById = async (id: string): Promise<MappingRule> => {
    const data = await getAuthTokens();
    const accessToken = data?.access_token;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${accessToken}`);

    myHeaders.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    const url = `${BACKEND_API_URL}/api/v1/mapping-rules/${id}`;

    return fetch(url, requestOptions).then(async (response) => {
        const data = await response.json();
        if (response.ok) {
            return transformMappingRuleForClient(data);
        } else {
            return Promise.reject({ ...data, statusCode: response.status });
        }
    });
};
