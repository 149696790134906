import { Dictionary } from "lodash";
import { CubeQueryBuilder } from "Utils/cubejs-utils";
import { CubeName } from "../../../dashboard-utils";
import { buildCubeFilters } from "Utils/mapping-configurator-utils";

export function makeSalesAndStaffHoursQuery(
    groupServices: string[],
    start: string,
    end: string,
    numberOfWeeksSelected: number,
    serviceFilterForExclusionRule: {
        member: string;
        values: string[];
        operator: string;
    }[],
    excludedMappingResults: any[],
    serviceIdsByIntegrationName: Dictionary<number[]> | undefined,
    selectedHours: number[] | undefined,
    selectedDaysToNum: any[] | undefined
) {
    return CubeQueryBuilder({
        measures: [
            `${CubeName.noPreAgg}.transactionTotal`,
            `${CubeName.noPreAgg}.activeStaffHourly`,
        ],
        order: { [`${CubeName.noPreAgg}.period`]: "asc" },
        dimensions: [
            "foreignVenue",
            "foreignArea",
            "foreignClass",
            "foreignVenueID",
            "foreignAreaID",
            "serviceId",
        ].map((dimension) => `${CubeName.noPreAgg}.${dimension}`),
        filters: [
            {
                member: `${CubeName.noPreAgg}.serviceId`,
                values: groupServices,
                operator: "equals",
            },
        ],
    })
        .addFilters(
            [
                {
                    member: `${CubeName.noPreAgg}.period`,
                    values: [start, end],
                    operator: "inDateRange",
                },
            ],
            numberOfWeeksSelected >= 52
        )
        .addTimeDimensions(
            [
                {
                    dimension: `${CubeName.noPreAgg}.period`,
                    dateRange: [start, end],
                    granularity: "year",
                },
            ],
            numberOfWeeksSelected < 52
        )
        .addFilters(
            [
                {
                    or: [
                        ...serviceFilterForExclusionRule,
                        ...excludedMappingResults.map((result) => {
                            return {
                                and: [
                                    {
                                        member: `${CubeName.noPreAgg}.serviceId`,
                                        values: groupServices,
                                        operator: "equals",
                                    },
                                    ...buildCubeFilters({
                                        cubeName: CubeName.noPreAgg,
                                        mappingResults: [result],
                                        operator: "notEquals",
                                        serviceIdsByIntegrationName,
                                    }),
                                ],
                            };
                        }),
                    ],
                },
            ],
            excludedMappingResults.length > 0
        )
        .addFilters(
            [
                {
                    member: `${CubeName.noPreAgg}.hour`,
                    operator: "equals",
                    values: selectedHours?.map(String),
                },
            ],
            selectedHours != undefined &&
                selectedHours.length !== 0 &&
                selectedHours.length !== 24
        )
        .addFilters(
            [
                {
                    member: `${CubeName.noPreAgg}.shiftDay`,
                    operator: "equals",
                    values: selectedDaysToNum,
                },
            ],
            selectedDaysToNum !== undefined && selectedDaysToNum?.length !== 0
        )
        .getResult();
}
