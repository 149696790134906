import { Segment } from "Api/mappingConfigurator";
import { CubeContext } from "@cubejs-client/react";
import {
    useListIntegrationSources,
    useListMappingRules,
} from "Hooks/mappingConfigurator";
import { chain } from "lodash";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { useDaysFilter, useHoursFilter, useSegmentsFilter } from "Store/filterStore";
import {
    calculateNumberOfDaysAndWeeksSelectedInRange,
    DateRange,
} from "Utils/date-utils";
import { SaleAndStaffHoursBySegment } from "../../../dashboard-model";
import {
    CubeName,
    getDefaultTotalSalesAndStaffHours,
    QueryKey,
} from "../../../dashboard-utils";
import { MappingRuleV2, fetchAllSegments } from "Api/mappingConfiguratorV2";
import { convertUnmappedSegmentResponseToSegments } from "Pages/MappingConfigurator/MappingConfiguratorEditorPage.logic";
import { useMergeMappingRulesAndData } from "./useMergeMappingRulesAndData";
import { makeSalesAndStaffHoursQuery } from "./makeSalesAndStaffHoursQuery";

interface GetSalesAndStaffHours {
    range: DateRange<string>;
    enabled?: boolean;
}

// MappingResultV2.result
type InputItem = {
    [key: string]: any;
    integrationSource: string;
};

// This is used to convert the numerical Ids (new mapping rules) into the string format (old mapping rules)
export function convertNumericalIdsToPseudoUniqueIds(
    input: InputItem[],
    segments: Segment[]
): any[] {
    return input.map((item) => {
        const keysToProcess = ["venueIds", "classIds", "areaIds"];
        const resultItem: InputItem = { ...item };

        keysToProcess.forEach((key) => {
            if (Object.keys(item).includes(key)) {
                resultItem[key] = item[key].map((id: number) => {
                    if (typeof id !== "number") {
                        // In this scenario we assume that the id is already in the legacy format so there is no need for further transformation
                        return id;
                    }
                    const segment = segments.find(
                        (s) => s.id.toString() === id.toString()
                    );

                    if (segment) {
                        return `${segment.integrationName}_${segment.segmentId}_${segment.segmentName}`;
                    }
                    throw new Error(
                        `Could not find segment with id ${id} in the list of segments`
                    );
                });
            }
        });
        return resultItem;
    });
}

const dayMapper = {
    Monday: "1",
    Tuesday: "2",
    Wednesday: "3",
    Thursday: "4",
    Friday: "5",
    Saturday: "6",
    Sunday: "7",
};

// Custom Hook for fetching Sales and Staff Hours data by Dates
export const useGetSalesAndStaffHoursByDates = (params: GetSalesAndStaffHours) => {
    // Extract parameters
    const {
        enabled = true,
        range: { start, end },
    } = params;

    // Get cubejsApi from CubeContext
    const { cubejsApi } = useContext(CubeContext);

    // Use custom hooks to fetch and filter different types of data
    const { selectedAreas, selectedClasses, selectedVenues } = useSegmentsFilter();
    const { isLoading: loadingMappingRules, data: mappingRules } =
        useListMappingRules();
    const { data: integrationSources, isLoading: loadingIntegrationSources } =
        useListIntegrationSources();
    const { selectedHours } = useHoursFilter();
    const { selectedDays } = useDaysFilter();

    // Convert selected days to numbers
    const selectedDaysToNum = useMemo(
        () => selectedDays?.map((day) => dayMapper[day]),
        [selectedDays]
    );

    // Fetch all segments data
    const { data: segments, isLoading: v2SegmentsLoading } = useQuery(
        "V2_ALL_SEGMENTS",
        fetchAllSegments,
        {
            staleTime: Infinity,
            select: (result) => {
                if (!result) return [];
                return convertUnmappedSegmentResponseToSegments(result);
            },
        }
    );

    // Generate services IDs by Integration Name
    const serviceIdsByIntegrationName = integrationSources
        ? chain(integrationSources)
              .keyBy("integrationName")
              .mapValues("services")
              .value()
        : undefined;

    // Organize Mapping Rules into included and excluded
    const allMappingRules = mappingRules?.reduce<{
        excludedMappingRules: MappingRuleV2[];
        mappingRules: MappingRuleV2[];
    }>(
        (result, mappingRule) => {
            if (mappingRule.exclude) {
                result.excludedMappingRules.push(mappingRule);
            } else {
                result.mappingRules.push(mappingRule);
            }
            return result;
        },
        {
            excludedMappingRules: [],
            mappingRules: [],
        }
    );

    // Convert Mapping Rules
    const convertedMappingRuleResult = useMemo(() => {
        if (!segments) return undefined;
        return allMappingRules?.mappingRules
            .filter((mappingRule) => !mappingRule.exclude)
            .map((mappingRule) => {
                const mappingRuleClone = { ...mappingRule };
                mappingRuleClone.result = convertNumericalIdsToPseudoUniqueIds(
                    mappingRule.result,
                    segments
                );
                return mappingRuleClone as MappingRuleV2;
            });
    }, [allMappingRules, segments]);

    // Group Services
    const groupServices = useMemo(
        () =>
            serviceIdsByIntegrationName
                ? Object.values(serviceIdsByIntegrationName).flatMap((value) =>
                      value.map(String)
                  )
                : undefined,
        [serviceIdsByIntegrationName]
    );

    // Determine excluded Mapping Results
    const excludedMappingResults = useMemo(() => {
        if (!segments) return undefined;
        return allMappingRules?.excludedMappingRules.flatMap(({ result }) =>
            convertNumericalIdsToPseudoUniqueIds(result, segments)
        );
    }, [allMappingRules?.excludedMappingRules]);

    // Apply service filter for exclusion rule
    const serviceFilterForExclusionRule = useMemo(() => {
        if (
            !excludedMappingResults ||
            !serviceIdsByIntegrationName ||
            !groupServices
        )
            return [];
        const serviceIds = chain(excludedMappingResults)
            .groupBy("integrationSource")
            .flatMap(
                (_, integrationSource) =>
                    serviceIdsByIntegrationName[integrationSource]
            )
            .value();
        if (serviceIds.length === groupServices.length) {
            return [];
        }
        return [
            {
                member: `${CubeName.noPreAgg}.serviceId`,
                values: groupServices.filter(
                    (serviceId) => !serviceIds.includes(Number(serviceId))
                ),
                operator: "equals",
            },
        ];
    }, [excludedMappingResults, groupServices, serviceIdsByIntegrationName]);

    // Calculate number of weeks selected
    const { numberOfWeeksSelected } = useMemo(() => {
        return calculateNumberOfDaysAndWeeksSelectedInRange(start, end);
    }, [end, start]);

    // Create a query if enabled and necessary data is available
    const query = useMemo(
        () =>
            enabled && excludedMappingResults !== undefined && groupServices?.length
                ? makeSalesAndStaffHoursQuery(
                      groupServices,
                      start,
                      end,
                      numberOfWeeksSelected,
                      serviceFilterForExclusionRule,
                      excludedMappingResults,
                      serviceIdsByIntegrationName,
                      selectedHours,
                      selectedDaysToNum
                  )
                : null,
        [
            enabled,
            excludedMappingResults,
            groupServices,
            numberOfWeeksSelected,
            serviceFilterForExclusionRule,
            serviceIdsByIntegrationName,
            selectedHours,
            selectedDaysToNum,
            start,
            end,
        ]
    );

    // Use cubejsApi to load the query data
    const {
        isLoading: fetchingSalesAndStaffHours,
        data: saleAndStaffHoursBySegment,
    } = useQuery(
        [QueryKey.salesAndStaffHours, query],
        () => cubejsApi.load(query!),
        {
            enabled: Boolean(query),
            select: (result) => {
                return result.rawData().map((d) => ({
                    activeStaff: d[`${CubeName.noPreAgg}.activeStaffHourly`],
                    areaName: d[`${CubeName.noPreAgg}.foreignArea`],
                    areaId: d[`${CubeName.noPreAgg}.foreignAreaID`],
                    className: d[`${CubeName.noPreAgg}.foreignClass`],
                    venueName: d[`${CubeName.noPreAgg}.foreignVenue`],
                    venueId: d[`${CubeName.noPreAgg}.foreignVenueID`],
                    serviceId: d[`${CubeName.noPreAgg}.serviceId`],
                    transactionTotal: d[`${CubeName.noPreAgg}.transactionTotal`],
                })) as SaleAndStaffHoursBySegment[];
            },
        }
    );

    // Map mapping rules to Segment IDs
    const mappingRuleBySegmentId = useMemo(() => {
        const mappingRuleBySegmentId: Record<string, string> = {};
        // Note: For classes they don't have a ID / SegmentID so we use the class name
        // This is ultimately not an ideal solution as there is a small possibility of collision but this
        // issue is ultimately something that needs to be resolved on the backend
        convertedMappingRuleResult?.forEach((mappingRule) => {
            mappingRule.unmapped_segments.forEach((segment) => {
                const key = `${segment.segment_type}-${
                    segment.segment_type === "class"
                        ? segment.segment_value
                        : segment.segment_id
                }-${segment.service_id}`;
                mappingRuleBySegmentId[key] = mappingRule.id;
            });
        });

        return mappingRuleBySegmentId;
    }, [convertedMappingRuleResult]);

    // Match mapping rules to sales and staff hours data
    const {
        totalSalesAndStaffHoursByMappingRule,
        newMappingRules: augmentedMappingRules,
    } = useMergeMappingRulesAndData(
        mappingRuleBySegmentId,
        convertedMappingRuleResult,
        saleAndStaffHoursBySegment,
        selectedAreas,
        selectedClasses,
        selectedVenues
    );

    // Calculate total sales and staff hours
    const totalSalesAndStaffHours = useMemo(() => {
        if (!augmentedMappingRules) {
            return undefined;
        }
        const result = {
            class: getDefaultTotalSalesAndStaffHours(),
            venue: getDefaultTotalSalesAndStaffHours(),
            area: getDefaultTotalSalesAndStaffHours(),
        };
        augmentedMappingRules.forEach((rule) => {
            const { staffHr, transactionTotal, segment_type } = rule;
            // We use just a single segment to prevent double/triple counting
            if (segment_type === "venue") {
                result.venue.totalSales += transactionTotal;
                result.venue.totalStaffHours += staffHr;
            } else if (segment_type === "class") {
                result.class.totalSales += transactionTotal;
                result.class.totalStaffHours += staffHr;
            } else if (segment_type === "area") {
                result.area.totalSales += transactionTotal;
                result.area.totalStaffHours += staffHr;
            }
        });
        // Find the local maximum value and use that as the max for the chart
        const maxSales = Math.max(
            result.venue.totalSales,
            result.class.totalSales,
            result.area.totalSales
        );

        const maxStaffHr = Math.max(
            result.venue.totalStaffHours,
            result.class.totalStaffHours,
            result.area.totalStaffHours
        );
        return { totalSales: maxSales, totalStaffHours: maxStaffHr };
    }, [augmentedMappingRules]);

    // Determine if data is still loading
    const isLoading =
        loadingMappingRules ||
        loadingIntegrationSources ||
        v2SegmentsLoading ||
        fetchingSalesAndStaffHours;

    // Return the result
    return {
        isLoading,
        query,
        totalSalesAndStaffHours,
        totalSalesAndStaffHoursByMappingRule,
        augmentedMappingRules,
    };
};
