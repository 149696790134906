import React, {
    ReactElement,
    useEffect,
    useState,
    useContext,
    useCallback,
} from "react";
import { useLocation } from "react-router";
import { Affix, Button, Layout, Result } from "antd";
import { animated, useSpring } from "react-spring";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import styled from "styled-components";

import { Context } from "State/store";
import { ENV, WRANGLR_LIGHT_BLUE } from "../Utils/constants";
import { RoutePaths } from "../Components/Router/Routes";
import TopNavigation from "../Components/TopNavigation";
import BaseSideNavigation from "../Components/SideNavigation/BaseSideNavigation";
import SideNavigation from "../Components/SideNavigation";
import ForecastingSideNavigation from "../Components/ForecastingSideNavigation";
import { useReturnToHomeWithBreakpoint } from "Components/RedirectMobileWrapper/RedirectMobileWrapper";

const AnimatedContent = animated(Layout.Content);

const MarginContent = styled(AnimatedContent)`
    margin: 20px;
`;

const StyledWarningBanner = styled.div`
    display: flex;
    width: 100%;
    background-color: ${WRANGLR_LIGHT_BLUE};
    color: #fff;
    margin-top: 2px;
    border-radius: 2px;
    padding: 8px;
    text-align: center;
    align-content: center;
    justify-content: center;
`;

const StyledAffix = styled(Affix)`
    z-index: 1000 !important;
`;

interface Props {
    children: ReactElement | ReactElement[];
    page: string;
}

const FallbackRender = ({ error, page }: { error: Error; page: string }) => (
    <div>
        <Result
            status="error"
            title="Something went wrong"
            subTitle={
                ENV === "dev" ? (
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {error.message}
                        <br />
                        <br />
                        {error.stack}
                    </details>
                ) : null
            }
            extra={
                <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                        window.location.href =
                            page === "forecasting"
                                ? RoutePaths.FORECASTING_LANDING
                                : RoutePaths.HOME;
                    }}
                >
                    Go Home
                </Button>
            }
        />
    </div>
);

const BaseDesktopPage = ({ children, page }: Props): ReactElement => {
    useReturnToHomeWithBreakpoint({});

    const [{ groupData }] = useContext(Context);
    const location = useLocation<{ prevPath?: string }>();
    const navigatingFromHome = location.state?.prevPath === "/";

    const getSideNavigation = (navigatingFromHome: boolean, page: string) =>
        page === "home" ? (
            <SideNavigation />
        ) : (
            <ForecastingSideNavigation navigatingFromHome={navigatingFromHome} />
        );

    const [sideNav, setSideNav] = useState<ReactElement>(
        getSideNavigation(navigatingFromHome, page)
    );

    const styles = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
    });

    useEffect(() => {
        setSideNav(getSideNavigation(navigatingFromHome, page));
    }, [page, navigatingFromHome]);

    const FallbackRenderComponent = useCallback(
        (fallbackProps: FallbackProps) => (
            <FallbackRender error={fallbackProps.error} page={page} />
        ),
        [page]
    );

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <BaseSideNavigation page={page}>{sideNav}</BaseSideNavigation>
            <Layout>
                <StyledAffix>
                    <TopNavigation mode="desktop" page={page} />
                </StyledAffix>
                {groupData?.group?.bannerAlert && (
                    <StyledWarningBanner>
                        {groupData?.group.bannerAlert}
                    </StyledWarningBanner>
                )}
                <Layout.Content>
                    <ErrorBoundary fallbackRender={FallbackRenderComponent}>
                        <MarginContent style={styles}>{children}</MarginContent>
                    </ErrorBoundary>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: "center" }}>
                    Quantaco ©{new Date().getFullYear()} All rights reserved
                </Layout.Footer>
            </Layout>
        </Layout>
    );
};

export default BaseDesktopPage;
